import { useContext, useEffect, useRef, useState } from 'react';
import '../App.css';
import HeaderSection from '../components/header/HeaderSection';
import { autocomplete_reposearch, autocomplete_snippets, generate_chat_stream, generate_code_external, generate_code_stream, search_atom_search, search_snippets } from '../utilities/api';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminConsole from '../components/developer_assistant/console/AdminConsole';
import { appContext } from '../App';
import { useTranslation } from "react-i18next";
import { createSearchContext, getRandID } from '../utilities/utils';
import {useDebounce} from '../utilities/UseDebounce';
import AtomSearchTab from '../components/developer_assistant/atomSearch/AtomSearchTab';
import SnippetSearchTab from '../components/developer_assistant/snippetSearch/SnippetSearchTab';
import GenerativeAITab from '../components/developer_assistant/codeGen/GenerativeAITab';

function DeveloperAssistant() {
    const appState = useContext(appContext).state
    const userDetails = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : {}
    const [t] = useTranslation("common");
    const tabs = [
        { tabID: "codeRepos", tabName: ["Source Code Repos", "EVIDEN verified"], tabLabel: "Code Repositories" , questions:["Share the api docs for email notification service", "Implementations on smart cooking platform", "Augmented Reality in health care"]},
        { tabID: "codeSnips", tabName: ["Code Snippets", "EVIDEN verified"], tabLabel: "Code Snippets",questions:["Java program to Create Pdf using iText","Java program to Read Excel using Apache XSSF","Java program to compress file into Zip file"] },
        { tabID: "codeGen", tabName: ["Generative AI", "External"], tabLabel: "Generative Code", questions:["Encryption in Python using AES 256", "JWT validation in Swift", "JSON parsing in Java"] },
        { tabID: "E2ETesting", tabName: ["E2E Testing", "Gen & Predictive AI"], tabLabel: "E2E Testing", questions:[] },
    ]
    
    const [searchType, setSearchType] = useState({ tabID: "codeRepos", tabName: ["Source Code Repos", ""], tabLabel: "Code Repositories", questions:["Share the api docs for email notification service", "Implementations on smart cooking platform", "Augmented Reality in health care"]})
    // `searchQuery` stores the current search query string, 
    // `lastRepoSearchData` stores the data related to the last repository search, 
    // `lastSnippetSearchData` and `lastGenerateCodeData` store data related to the last snippet and code generation searches respectively. 
    // `spellCorrectedQuery` stores the spell-corrected query string (if applicable), 
    // `isMisspelled` is a flag that indicates whether the current search query is misspelled, 
    // `snippetSearchResults` and `atomSearchResults` store the results of snippet and atom searches respectively, 
    // `codeGenResults` stores the generated code.
    const [searchQuery, setSearchQuery] = useState("");
    const [lastRepoSearchData, setLastRepoSearchData] = useState({query: "", results: [], chatPrompt: ""});
    const [lastSnippetSearchData, setLastSnippetSearchData] = useState("");
    const [lastGenerateCodeData, setLastGenerateCodeData] = useState("");
    const [spellCorrectedQuery, setSpellCorrectedQuery] = useState("");
    const [isMisspelled, setMisspelled] = useState(false);
    const [snippetSearchResults, setSnippetSearchResults] = useState([]);
    const [atomSearchResults, setAtomSearchResults] = useState([]);
    const [codeGenResults, setCodeGenResults] = useState("");
    
    // `autoCompleteLoading` is a flag that indicates whether the auto-completion feature is currently loading, 
    // `activeCompletion` stores the currently active auto-completion result, 
    // `autoCompleteResults` stores the list of auto-completion results, 
    // `showCompletion` is a flag that indicates whether the auto-completion results should be displayed.
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [activeCompletion, setActiveCompletion] = useState(null);
    const [autoCompleteResults, setAutoCompleteResults] = useState([]);
    const [showCompletion, setShowCompletion] = useState(false);

    // `chatResponse` stores the response received from the chatbot, 
    // `isChatStreamOpen` is a flag that indicates whether the chat stream is currently open, 
    // `chatResponseLoading` is a flag that indicates whether the chatbot response is currently loading, 
    // `isSnippetManageOpen` is a flag that indicates whether the snippet management functionality is currently open.
    const [chatResponse, setChatResponse] = useState('');
    const [isChatStreamOpen, setChatStreamOpen] = useState(false)
    const [chatResponseLoading, setChatResponseLoading] = useState(false);
    const [isSnippetManageOpen, setSnippetManageOpen] = useState(false)
    
    // `isSearchMinimized` is a flag that indicates whether the search UI is currently minimized, 
    // `isLoading` is a flag that indicates whether the search functionality is currently loading.
    const [isSearchMinimized, setSearchMinimize] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    // `isGenAiStreamOpen` is a flag that indicates whether the generative AI stream is currently open
    const [isGenAiStreamOpen, setGenAiStreamOpen] = useState(false)

    const debouncedQuery = useDebounce(searchQuery, 300)
    const autoCompleteRef = useRef(null)
    const searchResultsRef = useRef(null)
    const wsRef = useRef(null)
    const genAIStreamRef = useRef(null)
    autoCompleteRef.current = showCompletion
    searchResultsRef.current = {atom:atomSearchResults, snippet:snippetSearchResults, codegen: codeGenResults}

    useEffect(() => {
        // console.log("debouncedQuery", debouncedQuery, autoCompleteRef.current, (debouncedQuery || searchQuery.length < 0) && !isLoading)
        if ((debouncedQuery || searchQuery.length < 0) && autoCompleteRef.current) {
            if (searchType.tabID === "codeRepos") {
                getAutoCompletion(debouncedQuery, autocomplete_reposearch)
            }
            else if (searchType.tabID === "codeSnips") {
                getAutoCompletion(debouncedQuery, autocomplete_snippets)
                // console.log()
            }
            else {
                // console.log("code generation")
            }
        }
        else {
            setShowCompletion(false)
            setAutoCompleteResults([])
        }
        ;
      }, [debouncedQuery])

    useEffect(() => {
        setAutoCompleteResults([])
        setShowCompletion(false)
        setMisspelled(false)
        setAutoCompleteLoading(false)
        if (searchType.tabID === "codeRepos") {
            if (atomSearchResults.length > 0) {
                setSearchMinimize(true)
            }
            else {
                setSearchMinimize(false)
            }
        }
        else if  (searchType.tabID === "codeSnips") {
            if (snippetSearchResults.length > 0) {
                setSearchMinimize(true)
            }
            else {
                setSearchMinimize(false)
            }
        }
        else if (searchType.tabID === "codeGen") {
            if (Object.keys(codeGenResults).length > 0) {
                setSearchMinimize(true)
            }
            else {
                setSearchMinimize(false)
            }
        }
    }, [searchType])

    const onSearchKeyDown = (e, query) => {    
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            if (activeCompletion===null) {
                suggestionClickEvent(query)
            }
            else{
                suggestionClickEvent(autoCompleteResults[activeCompletion])
            }
        } else if (e.keyCode === 38) {
            e.preventDefault();
          if (activeCompletion === 0) {
            return;
          }
          setActiveCompletion(activeCompletion - 1);
        }
        else if (e.keyCode === 40) {
            e.preventDefault();
          if (activeCompletion + 1 === autoCompleteResults.length) {
            return;
          }
          setActiveCompletion(activeCompletion + 1);
        }
      };

    const atomSearch = (query) => {
        setLoading(true)
        setSearchMinimize(true)
        if (wsRef.current) {
            stopChatGeneration()
        }
        setChatResponseLoading(true)
        setChatResponse("")
        fetch(search_atom_search, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
            },
            body: JSON.stringify({ query: query })
        }).then((response) => {
            if (response.ok) { return response.json(); }
        })
        .then((json) => {
            if (json.status === 'success') {
                setLoading(false)
                setShowCompletion(false)
                setAutoCompleteResults([])
                setMisspelled(json.ai_response.spell_check.is_corrected)
                setSpellCorrectedQuery(json.ai_response.spell_check.corrected_query)
                if (json.ai_response.search_results.length > 0) {
                    setAtomSearchResults(json.ai_response.search_results)
                    const chatPrompt = createSearchContext(json.ai_response.search_results[0], query)
                    generateChat(chatPrompt, false)
                    setLastRepoSearchData({query: query, results: json.ai_response.search_results, chatPrompt: chatPrompt})
                } else {
                    setSearchMinimize(false)
                    setLastRepoSearchData({query: query, results: [], chatPrompt: ""})
                    setAtomSearchResults([])
                    toast.error(`No results found!`, {theme: "light", className:"", transition: Bounce, hideProgressBar:true, position: toast.POSITION.BOTTOM_CENTER})
                }
            }
        })
        .catch((error) => {
            // console.log(error);
        });
        // console.log("search function for code repo search")
    }

    const snippetSearch = (query) => {
        setLoading(true)
        setSearchMinimize(true)
        fetch(search_snippets, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
            },
            body: JSON.stringify({ query: query })
        }).then((response) => {
            if (response.ok) { return response.json(); }
        })
            .then((json) => {
                if (json.status === 'success') {
                    setLoading(false)
                    setShowCompletion(false)
                    setAutoCompleteResults([])
                    setMisspelled(json.ai_response.spell_check.is_corrected)
                    setSpellCorrectedQuery(json.ai_response.spell_check.corrected_query)
                    let rowsData = [];
                    if (json.ai_response.search_results.length > 0) {
                        json.ai_response.search_results.map((i, index) => {
                            rowsData.push({ score:i["score"], rowno: index, code: i["code"], name: i["name"],description: i["description"], lang: i["lang"], codeHeight: "200px", buttonText: "Expand", nooflines: "20" });

                        })
                    }
                    else {
                        setSearchMinimize(false)
                        setSnippetSearchResults([])

                        toast.error(`No results found!`, {theme: "light", className:"", transition: Bounce, hideProgressBar:true, position: toast.POSITION.BOTTOM_CENTER})
                    }
                    setSnippetSearchResults(rowsData)
                } 
            }
            )
            .catch((error) => {
                // console.log(error);
            });
    }

    const generateChat = (query, regenFlag) => {
        //function to fetch data from api and Generative AI
        wsRef.current = new WebSocket(generate_chat_stream);
        const temp = regenFlag? Math.max(Math.random().toFixed(1),0.1):"0.1";
        // console.log("temp", temp)
        setChatResponseLoading(true)
        setChatResponse("");
        setChatStreamOpen(true);

        const authenticationTimeout = setTimeout(() => {
            if (!authenticationReceived) {
                wsRef.current.close();
                setChatStreamOpen(false);
                setChatResponseLoading(false)
                setChatResponse("");
                // console.log("Authentication message not received within 5 seconds. WebSocket connection closed.");
            }
        }, 3000)
        
        let authenticationReceived = false;

        wsRef.current.addEventListener('open', function (event) {
            // Send message to server to initiate connection
            wsRef.current.send("sbaiportaluser:$yntBots@!");
            wsRef.current.send(`params_temperature:${temp}`);
            wsRef.current.send(query);
        });
        wsRef.current.addEventListener('message', function (event) {
            const message = event.data;
            if (message.toLowerCase().trim() === "eom" || message.toLowerCase().trim() === "<") {
                // console.log("End of message", searchResultsRef.current.atom);
                setChatResponse(response => response + "<b style='color: #002d3c;'>\nSource: " + searchResultsRef.current.atom[0]['bot_name'] + "</b>")
                wsRef.current.close();
                setChatStreamOpen(false);
            } else {
                // Append message to full response
                if (message.toLowerCase().trim() !== "authenticated"){
                    setChatResponseLoading(false)
                    setChatResponse(response => response + message);
                }
             else {
                authenticationReceived = true; // Set flag to true when authentication message is received
            }
            }
        });
        wsRef.current.addEventListener('close', function (event) {
            // WebSocket connection closed
            // console.log("WebSocket connection closed");
            setChatStreamOpen(false);
        });
    }
    
    const stopChatGeneration = () => {
        // console.log("closing websocket manually")
        wsRef.current.close();
        setChatStreamOpen(false);
    }

    const stopCodeGeneration = () => {
        // console.log("closing genAI stream manually")
        genAIStreamRef.current.cancel();
    }
    
    
    const generateCode = async (query, meta) => {
        const api_key = sessionStorage.getItem("openAiToken")
        const tokenUpdated = api_key.length > 0;

        if (tokenUpdated) {
            setLoading(true)
            setSearchMinimize(true)
            setCodeGenResults("")
            const decoder = new TextDecoder();
            const requestBody = { email: userDetails.email, query: query, meta: meta };

            const response = await fetch(generate_code_stream, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
                },
                body: JSON.stringify(requestBody)
            })
            if (genAIStreamRef.current !== null) {
                stopCodeGeneration();
            }
            setShowCompletion(false);
            setAutoCompleteResults([]);
            
            genAIStreamRef.current = response.body.getReader();
            let complete_response = "";

            genAIStreamRef.current.read().then(function processText({ done, value }) {
                if (searchResultsRef.current.codegen.length === 0) {
                        setLoading(false);
                        setGenAiStreamOpen(true)
                }

                if (done) {
                    if (searchResultsRef.current.codegen.length === 0) {
                        toast.error(
                        `We were not able to process this response at this moment, please try again later!`,
                        {
                            theme: "colored",
                            className: "text-[12px]",
                            transition: Bounce,
                            hideProgressBar: true,
                            position: toast.POSITION.TOP_RIGHT,
                        }
                        );
                    }
                    setGenAiStreamOpen(false);
                    // console.log("Stream complete", complete_response);
                    setCodeGenResults(complete_response)
                    // console.log("Stream complete");
                    return;
                }
                let string = decoder.decode(value);
                complete_response += string;
                setCodeGenResults(searchResultsRef.current.codegen + string)
                return genAIStreamRef.current.read().then(processText);
            });
        }
        else {
            toast.error(`Please add an OpenAI token to use this service.`, {theme: "colored", className:"text-[12px]", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
        }
    }
        

    const getAutoCompletion = (query, fetch_url) => {
        setAutoCompleteLoading(true)

        fetch(fetch_url, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
            },
            body: JSON.stringify({query : query})
        }).then((response) => {
            if (response.ok) { return response.json(); }
        })
        .then((json) => {
            if (json.status === 'success') {
                setAutoCompleteResults(json.ai_response)
                setShowCompletion(true)
                setAutoCompleteLoading(false)
                // console.log(json.ai_response)
            }
            else{
                setAutoCompleteResults([])
                setShowCompletion(false)
                setAutoCompleteLoading(false)
            }
        })
        .catch((error) => {
            // console.log(error);
        });
    }

    const suggestionClickEvent = (query, meta) => {
        setSearchQuery(query)
        onSearchEvent(query, meta)
        setAutoCompleteResults([])
        setShowCompletion(false)
        setAutoCompleteLoading(false)
    }

    const onSearchEvent = (query, meta) => {
        setShowCompletion(false)
        setAutoCompleteResults([])
        setAutoCompleteLoading(false)
        setMisspelled(false)
        if (searchType.tabID === "codeRepos") {
            atomSearch(query)
        }
        else if (searchType.tabID === "codeSnips") {
            snippetSearch(query)
        }
        
        else{
            generateCode(query, meta)
        }
    }

    const onTypeEvent = (query) => {
        setSearchQuery(query)
        setShowCompletion(true)
        setActiveCompletion(null)
        // if (searchType.tabID === "codeRepos") {
        //     getAutoCompletion(query, autocomplete_reposearch)
        // }
        // else if (searchType.tabID === "codeSnips") {
        //     getAutoCompletion(query, autocomplete_snippets)
        //     console.log()
        // }
        // else {
        //     console.log("code generation")
        // }
    }

    const clearSearch = () => {
        setSearchQuery('')
        setShowCompletion(false)
        setAutoCompleteResults([])
        setSearchMinimize(false)
        if (searchType.tabID === "codeRepos") {
            setAtomSearchResults([])
            setChatResponse("")
        }
        else if (searchType.tabID === "codeSnips") {
            setSnippetSearchResults([])
        }
        else {
            setCodeGenResults("")
        }
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setShowCompletion(false)
              setAutoCompleteResults([])
              setAutoCompleteLoading(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    return (
            <div className={`h-screen w-full px-4 text-skin-secondary ${appState.theme} bg-skin-primary`}>
                {isSnippetManageOpen && <AdminConsole handleClose={() => setSnippetManageOpen(false)}/>}
                {(autoCompleteResults.length!==0 && autoCompleteRef.current) && <div ref={wrapperRef} className={`absolute left-[50%] -translate-x-1/2 ${isSearchMinimized? "top-[20%]":"top-[20%]"} w-[50%] bg-skin-primary shadow-md border border-skin-accent overflow-clip rounded-xl z-10 flex flex-col divide-y divide-skin-backdrop`}>
                    {autoCompleteResults.map((val, i) => (<div key={i} className={`${activeCompletion===i ? "bg-skin-backdrop":""} py-2 text-xs px-3 hover:text-skin-accent duration-100 cursor-pointer`} onClick={() =>suggestionClickEvent(val)}>{val}</div>))}
                </div>}
                <HeaderSection appName={["Developer", "Assistant"]} selectedTab={searchType} tabSwitchEvent={setSearchType} tabs={tabs} adminClick={() => setSnippetManageOpen(true)}/>
                {/* <FilterSection /> */}

                {searchType.tabID === "codeRepos" &&
                    <AtomSearchTab searchQuery={searchQuery} isLoading={isLoading} atomSearchResults={atomSearchResults} chatResponse={chatResponse} generateChat={generateChat} lastRepoSearchData={lastRepoSearchData} chatResponseLoading={chatResponseLoading} isChatStreamOpen={isChatStreamOpen} stopChatGeneration={stopChatGeneration} selectedTab={searchType} isSearchMinimized={isSearchMinimized}  onTypeEvent={onTypeEvent} onSearchEvent={onSearchEvent} suggestionClickEvent={suggestionClickEvent} autoCompleteResults={autoCompleteLoading} clearSearch={clearSearch} isMisspelled={isMisspelled} spellCorrectedQuery={spellCorrectedQuery} onSearchKeyDown={onSearchKeyDown} autoCompleteLoading={autoCompleteLoading}/>
                }
                {searchType.tabID === "codeSnips" &&
                    <SnippetSearchTab searchQuery={searchQuery} snippetSearchResults={snippetSearchResults} isLoading={isLoading} selectedTab={searchType} isSearchMinimized={isSearchMinimized}  onTypeEvent={onTypeEvent} onSearchEvent={onSearchEvent} suggestionClickEvent={suggestionClickEvent} autoCompleteResults={autoCompleteLoading} clearSearch={clearSearch} isMisspelled={isMisspelled} spellCorrectedQuery={spellCorrectedQuery} onSearchKeyDown={onSearchKeyDown} autoCompleteLoading={autoCompleteLoading}/>
                }
                {searchType.tabID === "codeGen" &&
                    <GenerativeAITab searchQuery={searchQuery} codeGenResults={searchResultsRef.current.codegen} isStreamOpen={isGenAiStreamOpen} stopStreamEvent={stopCodeGeneration} isLoading={isLoading} selectedTab={searchType} isSearchMinimized={isSearchMinimized}  onTypeEvent={onTypeEvent} onSearchEvent={onSearchEvent} suggestionClickEvent={suggestionClickEvent} autoCompleteResults={autoCompleteLoading} clearSearch={clearSearch} isMisspelled={isMisspelled} spellCorrectedQuery={spellCorrectedQuery} onSearchKeyDown={onSearchKeyDown} autoCompleteLoading={autoCompleteLoading}/>
                }
                <ToastContainer className="z-umax" autoClose={4000}/>
            </div>
    );
}

export default DeveloperAssistant;
