import {motion} from 'framer-motion'

function BackDrop({children, onClick}) {
  return (
    <motion.div className='bg-opacity-30 z-max flex absolute top-0 left-0 h-full w-full bg-skin-secondary justify-center align-center overflow-y-hidden !mt-0'
     onClick={onClick}
     initial={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity:0}}
     >
        {children}
    </motion.div>
  )
}

export default BackDrop