import React, { useState } from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

function AccordianCard({data}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div
      className="border-none bg-skin-backdrop text-skin-secondary rounded-lg p-2 flex flex-col gap-2 text-[11px]"
    >
      <div
        className="cursor-pointer p-1"
        onClick={() => setExpanded(!expanded)}
      >
        <p className="font-semibold text-sky-700">{data.title}</p>
      </div>
      <div
        className={(expanded && data.meta_data.length!==0) ? 'bg-skin-primary rounded-lg p-2' : 'hidden'}
      >
        {data.meta_data.map((meta) => (
            <div key={meta.title} className="flex gap-2 items-center">
                <p>{meta.title}</p>
                <HiOutlineArrowNarrowRight />
                <p className="font-semibold">{meta.value}</p>
            </div>
        ))}
      </div>
      <div className={(expanded && data.thoughts.length!==0) ? 'p-2 bg-skin-accent text-skin-primary rounded-lg flex flex-col gap-2' : "hidden"}>
        <p className=''>Thoughts & Reasoning</p>
        {data.thoughts.map((thought) => (
            <div key={thought.title} className='flex gap-2 items-center'>
                <p>{thought.title}</p>
                <HiOutlineArrowNarrowRight className='shrink-0'/>
                <p className='break-words'>{thought.value}</p>
            </div>
        ))}
      </div>
    </div>
  );
}

export default AccordianCard;
