import React from 'react'
import { useTranslation } from "react-i18next";

function SuggestionSection({selectedTab, onSuggestionClick}) {
  const [t] = useTranslation("common");
  return (
    <div className='justify-around flex flex-col px-5 items-center'>
          <div className='text-skin-secondary text-[11px] font-semibold text-opacity-30 pb-2'>{t("Search.few_question")}.</div>
          <div className='flex items-center gap-4'>
            {selectedTab.questions.map((question) => (
              <div 
              key={question}
              className='outline-none cursor-pointer hover:border-skin-accent hover:text-skin-accent transform duration-150 active:scale-95 hover:scale-105 px-4 p-2 flex justify-between items-center opacity-80 rounded-xl bg-skin-primary text-xs border border-skin-secondary'
              onClick={() => onSuggestionClick(question)}
              >
                {question}
              </div>
            ))}
            
            {/* <div className='px-4 flex justify-between items-center opacity-80 text-sm rounded-xl bg-skin-primary h-10 w-40'>
              used for
              <BsChevronDown/>
            </div>
            <div className='px-4 flex justify-between items-center opacity-80 text-sm rounded-xl bg-skin-primary h-10 w-40'>
              Technology
              <BsChevronDown/>
            </div>
            <CiRedo className='rounded-xl bg-skin-primary h-9 w-9 p-2'/> */}
          </div>
          {/* <div className='text-gray-800 underline underline-offset-4 text-sm'>Clear all</div> */}
        </div>
  )
}

export default SuggestionSection