import { getBearerTokenAPI, getUserDetailsAPI, postOpenAiTokenAPI } from "./api";

export async function getBearerToken(token) {
    let access_token = ""
    await fetch(getBearerTokenAPI, {
        method:"POST",
        body: JSON.stringify({ token }),
        verify: false,
        headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.ok) { return response.json(); }
        })
        .then((json) => {
            access_token = json.access_token
        })
        .catch((error) => {
            // console.log(error);
        });
        return access_token
    }

export async function getOpenAiToken(email_id, access_token) {
    let token = ""
    const reqdata = {
        "email": email_id
    }
    // console.log(reqdata)
    await fetch(getUserDetailsAPI, {
        method:"POST",
        body: JSON.stringify(reqdata),
        verify: false,
        headers: {
            "Content-Type": "application/json",
            Authorization: "bearer "+ access_token
        },
        }).then((response) => {
            if (response.ok) { return response.json(); }
        })
        .then((json) => {
            token = json
        })
        .catch((error) => {
            // console.log(error);
        });
        return token
}

export async function postOpenAiToken(id, email_id, token, token_type, model_name, model_version, resource_endpoint) {
    let status = ""
    const reqdata = {
        "email": email_id,
        "token": token,
        "id": id,
        "model_name": model_name,
        "model_version": model_version,
        "resource_endpoint": resource_endpoint,
        token_type: token_type
    }
    await fetch(postOpenAiTokenAPI, {
        method:"POST",
        body: JSON.stringify(reqdata),
        verify: false,
        headers: {
            "Content-Type": "application/json",
            Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
        },
    }).then((response) => {
        if (response.ok) { return response.json(); }
    })
    .then((json) => {
        status = json.status
    })
    .catch((error) => {
        // console.log(error);
        });
    return status
}
