import React, { useState } from 'react'
import {CiGrid42, CiPaperplane} from "react-icons/ci"
import { useTranslation } from "react-i18next";
import SuggestionSection from './SuggestionSection'
import { VscLoading } from 'react-icons/vsc';
import { MdDoneAll, MdRemoveDone } from 'react-icons/md';
import SearchBar from './SearchBar';
import SearchArea from './SearchArea';

function SearchSection({selectedTab, isMinimized, query, queryOnChange, onSearch, onSuggestionClick, clearSearch, onKeyDownEvent, autoCompleteLoading, chatLoading, chatResponse, isLoading}) {
    const [t] = useTranslation("common");
    return (
        <div className={`py-6 gap-3 transform duration-150 ease-in-out relative bg-skin-backdrop rounded-xl justify-between flex overflow-clip flex-col shadow-md`}>
            <CiGrid42 className='absolute -z-10 text-skin-secondary opacity-10 h-60 w-60 rotate-[36deg] -left-14 -top-10'/>
            <CiPaperplane className='absolute -z-10 text-skin-secondary opacity-10 h-60 w-60 rotate-[45deg] right-10 -bottom-16'/>
            <div className='justify-center flex items-center gap-4 px-[25%] relative'>
                {
                    selectedTab.tabID!=="codeGen" ? 
                    <SearchBar query={query} queryOnChange={queryOnChange} onSearch={onSearch} onKeyDownEvent={onKeyDownEvent} autoCompleteLoading={autoCompleteLoading}/>
                    :
                    <SearchArea query={query} queryOnChange={queryOnChange} onSearch={onSearch} onKeyDownEvent={onKeyDownEvent} autoCompleteLoading={autoCompleteLoading}/>

                }
                {isMinimized && 
                    <span 
                    onClick={clearSearch}
                    className='absolute font-bold right-5 text-[12px] cursor-pointer active:text-red-600 duration-75 active:scale-95 hover:underline underline-offset-2'
                    >
                        Clear Results
                    </span>
                }
            </div>
            {(isMinimized && (selectedTab.tabID==="codeRepos")) && 
            <div className={`flex gap-3 items-center bottom-1 text-center text-[10px] leading-none absolute duration-100  transform left-1/2 -translate-x-1/2`}>
                <span className='flex gap-1 items-center '>
                    {isLoading ? <VscLoading className={`h-4 w-4 animate-spin`}/> : <MdDoneAll className='text-green-600 w-4 h-4'/>} Search
                </span>
                <span className='flex gap-1 items-center'>
                    {chatLoading? <VscLoading className={`h-4 w-4 animate-spin`}/> : (chatResponse.length===0 ? <MdRemoveDone className='text-skin-secondary w-4 h-4'/> : <MdDoneAll className='text-green-600 w-4 h-4'/>)} AI response
                </span>
            </div>}

            {!isMinimized &&
                <SuggestionSection selectedTab={selectedTab} onSuggestionClick={onSuggestionClick}/>
            }
        </div>
    )
}

export default SearchSection