import React, { useContext, useState } from 'react'
import {FaPaperPlane} from 'react-icons/fa'
import { MdOutlineClear } from 'react-icons/md'
import { FiInfo } from 'react-icons/fi'
import Editor from "@monaco-editor/react";
import { appContext } from '../../../App';
import { CiGrid42, CiPaperplane } from 'react-icons/ci';
import { supportedLanguages, validateCodeInput } from '../../../utilities/utils';
import CodeInputWarning from '../../../utilities/CodeInputWarning';

function GenerativeAISearchSection({selectedTab, searchQuery, onTypeEvent, onSearchEvent, suggestionClickEvent, clearSearch, onSearchKeyDown}) {
    const appState = useContext(appContext).state
    const [genQuery, setGenQuery] = React.useState(searchQuery)
    const [instructionType, setInstructionType] = React.useState('codeGen')
    const [translationTarget, setTranslationTarget] = React.useState('')
    const [isWarningOpen, setWarningOpen] = useState(false)

    const queryOnChange = (query) => {
        setGenQuery(query)
        onTypeEvent(query)
    }
    const sendQueryForCodeGen = () => {
        if (instructionType==="translateCode") {
            if (translationTarget.length!==0) {
                const modifiedQuery = genQuery
                onTypeEvent(modifiedQuery)
                onSearchEvent(modifiedQuery, {translationTarget:translationTarget, serviceCode:"translateCode"})
            }
            else {
                alert("Please select a target language to translate.")
            }
        }
        else if (instructionType==="codeGen"){
            onTypeEvent(genQuery)
            onSearchEvent(genQuery, {serviceCode:"codeGen"})
        }
        else if (instructionType==="explainCode") {
            // const modifiedQuery = `I don't know how to code, but I want to understand how this works. Explain the following code to me in a way that a non-technical person can understand. Always use Markdown with nice formatting to make it easier to follow. Organize it by sections with headers. Include references to the code as markdown code blocks in each section. The code:\n\n${genQuery}`
            onTypeEvent(genQuery)
            onSearchEvent(genQuery, {serviceCode:"explainCode"})
        }
        else if (instructionType==="unitTest") {
            onTypeEvent(genQuery)
            onSearchEvent(genQuery, {serviceCode:"unitTest"})
        }
        else if (instructionType==="custom") {
            onTypeEvent(genQuery)
            onSearchEvent(genQuery, {serviceCode:"custom"})
        }
    }

    const validateAndSendQuery = () => {
        if (!validateCodeInput){
            sendQueryForCodeGen()
        }
        else {
            setWarningOpen(true)
        }
    }

    const keyDownEvent = (e, query) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            validateAndSendQuery()
        } 
    }

  return (
    <form className='relative w-[40%] border-skin-backdrop p-8 pb-4 flex-col flex gap-5 shrink-0'>
        {isWarningOpen && <CodeInputWarning modalIsOpen={isWarningOpen} submitEvent={() => {sendQueryForCodeGen(); setWarningOpen(false)}} closeEvent={() => setWarningOpen(false)}/>}
        <CiGrid42 className='absolute text-skin-secondary opacity-10 h-60 w-60 rotate-[36deg] -left-14 -top-10'/>
        <CiPaperplane className='absolute text-skin-secondary opacity-10 h-60 w-60 rotate-[45deg] -right-20 top-36'/>
        <div className='flex items-center justify-center text-2xl mt-2 gap-2'>
            <p className=''>Generative AI</p>
            <FiInfo className='h-3.5 w-3.5 cursor-pointer text-skin-accent active:scale-95 hover:scale-105 duration-150'/>
        </div>
        {/* <SearchSection selectedTab={selectedTab} isMinimized={isSearchMinimized} query={searchQuery} queryOnChange={onTypeEvent} onSearch={onSearchEvent} onSuggestionClick={suggestionClickEvent} onTypeEvent={onTypeEvent} autoCompleteResults={autoCompleteResults} clearSearch={clearSearch} isMisspelled={isMisspelled} spellCorrectedQuery={spellCorrectedQuery} onKeyDownEvent={onSearchKeyDown} autoCompleteLoading={autoCompleteLoading} chatLoading={chatResponseLoading} chatResponse={chatResponse} isLoading={isLoading}/> */}
            <div className='shrink-0 flex items-center gap-2 flex-wrap'>
                <div className='flex-grow flex flex-col'>
                    <p className='px-1 text-[10px] text-skin-accent'>Instruction</p>
                    <select 
                    type="dropdown" 
                    defaultValue={instructionType} 
                    required
                    onChange={(e) => {setInstructionType(e.target.value);}} 
                    className=' outline-none text-xs rounded-lg border focus-within:border-skin-accent border-skin-secondary border-opacity-50 bg-skin-primary h-10 px-2 transform duration-150 ease-in-out'>
                        <option key={"codeGen"} value="codeGen">Generate Code</option>
                        <option key={"translateCode"} value="translateCode">Translate Code</option>
                        <option key={"explainCode"} value="explainCode">Explain Code</option>
                        <option key={"unitTest"} value="unitTest">Create Test Cases</option>
                        <option key={"custom"} value="custom">Custom</option>
                    </select>
                </div>
                {instructionType==="translateCode" && 
                <div>
                    <p className='px-1 text-[10px] text-skin-accent'>Translate to?</p>
                    <select 
                    type="dropdown" 
                    defaultValue={translationTarget} 
                    required
                    onChange={(e) => {setTranslationTarget(e.target.value);}} 
                    className='w-40 outline-none text-xs rounded-lg border focus-within:border-skin-accent border-skin-secondary border-opacity-50 bg-skin-primary h-10 px-2'>
                        <option value="" >{"select Language"}...</option>
                        {supportedLanguages.sort((a, b) => a.label.localeCompare(b.label)).map((lang, index) => (
                            <option key={index} value={lang.value}>{lang.label}</option>
                        ))}
                    </select>
                </div>
                }
        </div>
        <div className={`${instructionType!=="codeGen" ? "hidden":""} text-skin-secondary`}>
            <p className='text-[8px] text-center mb-2'>Here are a few examples of what I can help you with.</p>
            <div className='flex gap-2 justify-center  flex-wrap'>
                {selectedTab.questions.map((q, index) => (
                    <div key={index} onClick={() => suggestionClickEvent(q, {serviceCode:instructionType})} className='bg-skin-primary active:scale-95 overflow-clip font-normal text-xs shadow-skin-backdrop shadow-opacity-70 hover:border-skin-accent hover:text-skin-accent hover:shadow-md transform duration-150 cursor-pointer rounded-md border border-skin-secondary border-opacity-50 p-2 text-center flex items-center'>
                    {q}
                    </div>
                    
                ))}
            </div>
        </div>
        {/* <textarea className='rounded-lg border flex-grow'/> */}
        <div className='flex-grow flex relative flex-col transform duration-150 ease-in-out'>
            <p className='px-1 text-[10px] text-skin-accent'>Input</p>
            {(instructionType==="codeGen"||instructionType==="unitTest"||instructionType==="custom") &&
            <textarea
            type="text" 
            value={searchQuery}  
            onChange = {(event) => queryOnChange(event.target.value)}
            autoFocus
            className='p-4 text-skin-secondary border bg-skin-primary focus-within:border-skin-accent border-skin-secondary border-opacity-50 rounded-lg outline-none flex-grow text-xs   resize-none' 
            placeholder={`type your instructions here...`}
            />
            }
            {(instructionType==="translateCode" || instructionType==="explainCode") &&
            <div className='overflow-clip focus-within:border-skin-accent border-skin-secondary border-opacity-50 border  text-skin-secondary rounded-lg bg-skin-primary outline-none flex-grow text-xs   resize-none'>
                <Editor
                // className='!text-[10px] w-full h-full scrollbar-thin'
                theme={`${appState.theme === "theme-dark-blue" ? 'vs-dark':"light"}`}
                onChange={(event) => queryOnChange(event)}
                options={{
                    "acceptSuggestionOnCommitCharacter": true,
                    "acceptSuggestionOnEnter": "on",
                    "accessibilitySupport": "auto",
                    "autoIndent": false,
                    "automaticLayout": true,
                    "codeLens": true,
                    "colorDecorators": true,
                    "contextmenu": true,
                    "cursorBlinking": "blink",
                    "cursorSmoothCaretAnimation": false,
                    "cursorStyle": "line",
                    "disableLayerHinting": false,
                    "disableMonospaceOptimizations": false,
                    "dragAndDrop": false,
                    "fixedOverflowWidgets": false,
                    "folding": true,
                    "foldingStrategy": "auto",
                    "fontLigatures": false,
                    "formatOnPaste": false,
                    "formatOnType": false,
                    "hideCursorInOverviewRuler": false,
                    "highlightActiveIndentGuide": true,
                    "links": true,
                    "mouseWheelZoom": false,
                    "multiCursorMergeOverlapping": true,
                    "multiCursorModifier": "alt",
                    "overviewRulerBorder": true,
                    "overviewRulerLanes": 2,
                    "quickSuggestions": true,
                    "quickSuggestionsDelay": 100,
                    "readOnly": false,
                    "renderControlCharacters": false,
                    "renderFinalNewline": true,
                    "renderIndentGuides": true,
                    "renderLineHighlight": "all",
                    "renderWhitespace": "none",
                    "revealHorizontalRightPadding": 30,
                    "roundedSelection": true,
                    "rulers": [],
                    "scrollBeyondLastColumn": 5,
                    "scrollBeyondLastLine": false,
                    "selectOnLineNumbers": true,
                    "selectionClipboard": true,
                    "selectionHighlight": true,
                    "showFoldingControls": "mouseover",
                    "smoothScrolling": true,
                    "suggestOnTriggerCharacters": true,
                    "wordBasedSuggestions": true,
                    "wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
                    "wordWrap": "off",
                    "wordWrapBreakAfterCharacters": "\t})]?|&,;",
                    "wordWrapBreakBeforeCharacters": "{([+",
                    "wordWrapBreakObtrusiveCharacters": ".",
                    "wordWrapColumn": 80,
                    "wordWrapMinified": true,
                    "wrappingIndent": "none"
                }}
                defaultLanguage={"python"}
                language={"python"}
                value={searchQuery}
                />
            </div>
            }
            <div className='z-10 p-1 absolute bottom-4 right-4 flex gap-3 items-center'>
                {/* <MdOutlineClear className=" h-4 w-4 text-skin-secondary hover:text-red-500 hover:scale-110 active:scale-95 duration-150 transform cursor-pointer"/> */}
                {searchQuery.length!==0 && <p onClick={()=>queryOnChange("")} className='text-[10px] text-skin-secondary hover:text-red-500 hover:scale-110 active:scale-95 duration-150 transform cursor-pointer'>Clear</p>}
                <FaPaperPlane onClick={() => {validateAndSendQuery(genQuery)}} className=" h-7 w-7 text-skin-accent hover:scale-110 active:scale-95 duration-150 transform cursor-pointer"/>
            </div>
        </div>
    </form>
  )
}

export default GenerativeAISearchSection