import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { CgAddR } from 'react-icons/cg';
import { MdPublishedWithChanges } from 'react-icons/md';
import {FiEdit3} from "react-icons/fi"
import { CiRead } from 'react-icons/ci';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ToggleSwitch } from '../../../../utilities/ToggleSwitch';
import MultiSelectSearch from '../../../../utilities/MultiSelectSearch';
import { Bounce, toast } from 'react-toastify';
import { indexRepos } from '../../../../utilities/api';


function RepoEditor({setRecents, repoData, editType, cancelFormEvent, isReadOnly}) {
  const [bot_name, setBotName] = useState("")
  const [bot_summary, setBotSummary] = useState("")
  const [category, setCategory] = useState("")
  const [sub_category, setSubCategory] = useState("")
  const [tags, setTags] = useState("")
  const [status, setStatus] = useState("")
  const [how_to_use, setHowToUse] = useState("")
  const [how_to_extend_and_customize, setHowToExtendAndCustomize] = useState("")
  const [used_for, setUsedFor] = useState("")
  const [source_code, setSourceCode] = useState("")
  const [demo_link, setDemoLink] = useState("")
  const [api_docs, setApiDocs] = useState("")
  const [docker_image, setDockerImage] = useState("")

  useEffect(() => {
      setBotName(repoData.bot_name)
      setBotSummary(repoData.bot_summary)
      setCategory(repoData.category)
      setSubCategory(repoData.sub_category)
      setTags(repoData.tags)
      setStatus(repoData.status)
      setHowToUse(repoData.how_to_use)
      setHowToExtendAndCustomize(repoData.how_to_extend_and_customize)
      setUsedFor(repoData.used_for)
      setSourceCode(repoData.source_code)
      setDemoLink(repoData.demo_link)
      setApiDocs(repoData.api_docs)
      setDockerImage(repoData.docker_image)
  }, [repoData])
  const [t] = useTranslation("common");
  const deleteFucntion = () => {
  }
  const saveFunction = (e) => {
    e.preventDefault();
    console.log("save function")
    if (true) {
      const reqBody = {
        bot_id: repoData.bot_id,
        bot_name: bot_name,
        bot_summary: bot_summary,
        category: category,
        sub_category: sub_category,
        tags: tags,
        status: status,
        how_to_use: how_to_use,
        how_to_extend_and_customize: how_to_extend_and_customize,
        used_for: used_for,
        source_code: source_code,
        demo_link: demo_link,
        api_docs: api_docs,
        docker_image: docker_image,
        author: repoData.author,
      }
      const publishPromise = new Promise((resolve) => fetch(indexRepos, {
        method:"POST", 
        headers: {
            "Content-Type": "application/json",
            Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
        },
        body: JSON.stringify(reqBody)
        }).then((response) => {
            if (response.ok) { 
                return response.json();
        }
        })
        .then((json) => {
            if (json.status === 'success') {
                setRecents(reqBody)
                resolve(json)    
            }
            else {
                console.log(json)
            }
        })
        .catch((error) => {
            console.log(error);
        })
        );
        toast.promise(publishPromise, {
            pending: 'Making a publish request',
            success: "Published!",
            error: 'Failed!'
        }, { transition: Bounce, hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER })
    }
    else {
    }
  }

  return (
   <div className='w-[70%] flex flex-col rounded-t-md overflow-clip overflow-y-scroll mr-1 border border-b-0 border-skin-secondary border-opacity-30 pb-2 relative'>
    {/* <div className='z-10 absolute bg-skin-accent w-full h-5 bottom-0'></div> */}
    <form onSubmit={(e) => saveFunction(e)} className={`shrink-0 flex flex-col bg-skin-primary text-skin-secondary px-4`}>
      <fieldset disabled={isReadOnly}>
        <div className='sticky z-10 top-0 bg-skin-primary flex items-center justify-between h-9 p-1 border-skin-backdrop'>
          <div className={`${isReadOnly ? "text-red-500": editType==="edit" ? "text-green-500":"text-yellow-500"} text-xs flex font-bold gap-2 items-center`}>
              {editType==="edit" ? (<FiEdit3 className='h-4 w-4'/>): !isReadOnly? (<CgAddR className='h-4 w-4'/>):(<CiRead className='h-4 w-4'/>)}
              {editType==="edit" ? (t("AdminConsole.Edit_Repo")) : !isReadOnly? (t("AdminConsole.Add_Repo")):(t("AdminConsole.View_Repo"))}
              <p className='text-[8px] opacity-60 '>#{repoData.bot_id}</p>
          </div>
          <div className='flex gap-4 items-center'>
              <button type='submit' className='disabled:cursor-not-allowed flex gap-1 group hover:text-green-500 active:scale-95 hover:scale-105 duration-100 items-center text-sm'><MdPublishedWithChanges onClick={() => saveFunction()} className='h-4 w-4'/>{t("AdminConsole.Publish")}</button>
              <IoIosCloseCircleOutline onClick={() => cancelFormEvent()} className='disabled:cursor-not-allowed hover:text-red-500 hover:scale-105 duration-100 h-5 w-5 cursor-pointer'/>
              <AiOutlineInfoCircle onClick={() => deleteFucntion()} className='disabled:cursor-not-allowed hover:text-skin-accent hover:scale-105 duration-100 h-5 w-5 cursor-pointer'/>
          </div>
        </div>
        <div className='flex flex-col gap-2 mt-3 p-1 opacity-50 cursor-not-allowed'>
          <p className='font-semibold text-sm text-skin-accent'>Meta Data</p>
          <div className='flex gap-8 justify-between items-center mx-2'>
            <div className='flex-grow'>
              <p className='text-[8px]'>{"repository ID"}</p>
              <input
              type="text" 
              value={repoData.bot_id}  
              disabled
              className='outline-none w-full text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent' 
              />
            </div>
            <div className='flex-grow'>
              <p className='text-[8px]'>{"creation date"}</p>
              <input
              type="text" 
              value={repoData.creation_date}  
              disabled
              className='outline-none w-full text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent' 
              />
            </div>
            <div className='flex-grow'>
              <p className='text-[8px]'>{"author"}</p>
              <input
              type="text" 
              value={repoData.author}  
              disabled
              className='outline-none w-full text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent' 
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-2 mt-3 p-1'>
          <p className='font-semibold text-sm text-skin-accent'>Basic Information</p>
          <div className='grid grid-cols-3 gap-x-8 gap-y-3 justify-between items-center mx-2'>
            <div className=''>
              <p className='required-field text-skin-accent font-medium text-[8px]'>{"name"}</p>
              <input
              type="text" 
              required
              value={bot_name}  
              onChange={(e) => setBotName(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent' 
              placeholder='Enter name'
              />
            </div>
            <div className=''>
              <p className='required-field text-skin-accent font-medium text-[8px]'>{"category"}</p>
              <input
              type="text"
              required
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent'
              placeholder='Enter category'
              />
            </div>
            <div className=''>
              <p className='required-field text-skin-accent font-medium text-[8px]'>{"sub category"}</p>
              <input
              type="text"
              required
              value={sub_category}
              onChange={(e) => setSubCategory(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent'
              placeholder='Enter sub category'
              />
            </div>
            <div className='col-span-3'>
                <p className='required-field text-skin-accent font-medium text-[8px]'>{"summary"}</p>
                <textarea
                required
                value={bot_summary}  
                onChange={(e) => setBotSummary(e.target.value)}
                className='resize-none disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 px-3 bg-transparent' 
                placeholder="Enter summary"
                />
            </div>
            <div className='col-span-2'>
                <p className='text-skin-accent font-medium text-[8px]'>{t("AdminConsole.Tags")}</p>
                <MultiSelectSearch value={tags} setValue={setTags}/>
            </div>
            <div className='flex items-center gap-2 mt-auto'>
              <p className='text-skin-accent font-medium text-[8px]'>Active</p>
              <ToggleSwitch   
                checked={status}
                onChange={() => setStatus(!status)}
                inputProps={{ 'aria-label': 'controlled' }} 
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-2 mt-5 p-1'>
          <p className='font-semibold text-sm text-skin-accent'>{"Usage"}</p>
          <div className='grid grid-cols-2 gap-x-8 gap-y-3 justify-between items-center mx-2'>
            <div className='col-span-2'>
                <p className='required-field text-skin-accent font-medium text-[8px]'>{"how to use?"}</p>
                <textarea
                required
                value={how_to_use}  
                onChange={(e) => setHowToUse(e.target.value)}
                className='resize-none disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 px-3 bg-transparent' 
                placeholder={`Describe how to use the bot`}
                />
            </div>
            <div className=''>
                <p className='required-field text-skin-accent font-medium text-[8px]'>{"how to extend and customize?"}</p>
                <textarea
                required
                value={how_to_extend_and_customize}  
                onChange={(e) => setHowToExtendAndCustomize(e.target.value)}
                className='resize-none disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 px-3 bg-transparent' 
                placeholder={`Describe how to extend and customize the bot`}
                />
            </div>
            <div className=''>
                <p className='required-field text-skin-accent font-medium text-[8px]'>{"used for?"}</p>
                <textarea
                required
                value={used_for}
                onChange={(e) => setUsedFor(e.target.value)}
                className='resize-none disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 px-3 bg-transparent' 
                placeholder={`Describe what the bot is used for`}
                />
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-2 mt-5 p-1'>
          <p className='font-semibold text-sm text-skin-accent'>{"Access Links"}</p>
          <div className='grid grid-cols-1 gap-y-3 justify-between items-center mx-2'>
            <div className='flex items-center justify-between relative'>
              <p className='required-field text-skin-accent font-medium text-[10px] whitespace-nowrap'>{"source code"}</p>
              <input
              type="text" 
              required
              value={source_code}  
              onChange={(e) => setSourceCode(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-[80%] focus:border-skin-accent text-[10px] border border-skin-backdrop rounded-md p-1 px-3 bg-transparent' 
              placeholder='Enter source code link'
              />
            </div>
            <div className='flex items-center justify-between relative'>
              <p className='text-skin-accent font-medium text-[10px] whitespace-nowrap'>{"API documentation"}</p>
              <input
              type="text" 
              value={api_docs}  
              onChange={(e) => setApiDocs(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-[80%] focus:border-skin-accent text-[10px] border border-skin-backdrop rounded-md p-1 px-3 bg-transparent' 
              placeholder='Enter API documentation link'
              />
            </div>
            <div className='flex items-center justify-between relative'>
              <p className='text-skin-accent font-medium text-[10px] whitespace-nowrap'>{"docker image"}</p>
              <input
              type="text" 
              value={docker_image}  
              onChange={(e) => setDockerImage(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-[80%] focus:border-skin-accent text-[10px] border border-skin-backdrop rounded-md p-1 px-3 bg-transparent' 
              placeholder='Enter docker image link'
              />
            </div>
            <div className='flex items-center justify-between relative'>
              <p className='text-skin-accent font-medium text-[10px] whitespace-nowrap'>{"demo link"}</p>
              <input
              type="text" 
              value={demo_link}  
              onChange={(e) => setDemoLink(e.target.value)}
              className='disabled:cursor-not-allowed outline-none w-[80%] focus:border-skin-accent text-[10px] border border-skin-backdrop rounded-md p-1 px-3 bg-transparent' 
              placeholder='Enter demo link'
              />
            </div>
          </div>
        </div>
      </fieldset>
    </form>

  </div>
  )
}

export default RepoEditor