import React from 'react'
import { BsCodeSlash } from 'react-icons/bs'
import { CiSearch } from 'react-icons/ci'
import ThreeDots from 'react-loading-icons/dist/esm/components/three-dots'
import SearchSection from '../search_section/SearchSection'
import GenerativeAIResultSectionv2 from './GenerativeAIResultSectionv2'
import code_icon from "../../assets/code1.png"

import GenerativeAISearchSection from './GenerativeAISearchSection'

function GenerativeAITab({searchQuery, codeGenResults, isStreamOpen, stopStreamEvent, isLoading, selectedTab, onTypeEvent, onSearchEvent, suggestionClickEvent, clearSearch, onSearchKeyDown}) {

    return (
    <div className={`h-[90%] bg-skin-backdrop text-skin-widget-text relative flex border-x border-t overflow-clip border-skin-backdrop rounded-t-lg`}>
                    
        <GenerativeAISearchSection selectedTab={selectedTab} searchQuery={searchQuery} onTypeEvent={onTypeEvent} onSearchEvent={onSearchEvent} suggestionClickEvent={suggestionClickEvent} clearSearch={clearSearch} onSearchKeyDown={onSearchKeyDown}/>
        
        <div className='w-[60%] relative border bg-skin-primary border-skin-secondary border-opacity-50 border-x my-4 mr-4 rounded-lg p-4 pt-5 shadow-inner shadow-skin-backdrop text-skin-secondary'>
            {isStreamOpen && <div onClick={() => stopStreamEvent()} className='z-10 bg-skin-accent absolute bottom-4 shadow-lg left-1/2 -translate-x-1/2 px-2 p-1 rounded-md text-[10px] whitespace-nowrap text-skin-primary cursor-pointer duration-100 transform hover:scale-105 active:scale-95'>Stop Generation</div>}
            
            {Object.keys(codeGenResults).length!==0 && <p onClick={()=> clearSearch()} className='text-[11px] font-semibold absolute bottom-1 left-4 cursor-pointer hover:text-red-500 active:scale-90 duration-150'>Clear Results</p>}
            {!isLoading ? 
                (Object.keys(codeGenResults).length!==0 ?
                    (
                        <GenerativeAIResultSectionv2 searchQuery={searchQuery} codeGenResults={codeGenResults}/>
                    )
                    :
                    (
                        <div className='stroke-skin-accent m-auto h-60 text-[12px] text-skin-secondary pt-24'>
                            {/* <BsCodeSlash className='opacity-40 h-20 w-20 m-auto mb-6'/> */}
                            <img src={code_icon} className='h-20 w-20 m-auto mb-6'/>
                            <p className=' text-center m-auto max-w-[550px]'>Generative AI connects to external api's to generate relevant code, <br/>Please validate the code generated through this platform before use, since this service is still in beta.</p>
                            <div className='mt-6 font-medium'>
                            <ul>
                                <li className='flex gap-3 text-skin-accent justify-center items-center'>use the <CiSearch className='h-4 w-4'/> section to use Generative AI.</li>
                                <li className='flex gap-3 text-[10px] my-1.5 justify-center items-center'>(or)</li>
                                <li className='flex gap-3 text-skin-accent justify-center items-center'>Switch to Code Snippets section to find useful EVIDEN verified code snippets.</li>
                            </ul>
                            </div>
                        </div>
                    )
                )
                :
                (
                    <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-10 h-40' />
                )
            }
        </div>
    </div>
  )
}

export default GenerativeAITab