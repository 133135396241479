import React from 'react'
import { MdDoneAll, MdRemoveDone } from 'react-icons/md'
import { VscLoading } from 'react-icons/vsc'

function LoadingSection({isLoading, chatLoading, isSearchsuccess, isAIsuccess}) {
  return (
    <div className={`flex gap-3 items-center font-light`}>
        {isLoading && <><VscLoading className='animate-spin w-4 h-4'/></>}
    </div>
  )
}

export default LoadingSection