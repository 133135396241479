import React, { useEffect, useRef} from 'react'
import {GoThumbsup, GoThumbsdown} from "react-icons/go"
import Highlight from 'react-highlight'
import ReactMarkdown from 'react-markdown'
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import CodeBlock from './markdowns/CodeBlock';

function GenerativeAIResultSectionv2({searchQuery, codeGenResults, animate}) {
  useEffect(() => {
      scrollToBottom(10)
  }, [codeGenResults])

  const messagesEndRef = useRef(null)

  const scrollToBottom = (timeout) => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, timeout);
  }

  return (
    <div className={`h-full flex overflow-scroll justify-around gap-1 transform duration-150 ease-in-out relative mb-2`}>
        <div data-color-mode="dark" className="pl-2 w-full overflow-scroll flex-col flex gap-2" >
          <div className='flex justify-end items-center gap-3 text-skin-accent px-2'>
            <p className='text-[9px] font-medium text-orange-500 flex-grow'>The following response was generated by a third-party generative AI and may not be accurate or reliable. Use at your own risk. Additionally, any code provided is not tested and may have security flaws. It is recommended to review this code by a senior architect before it is used in projects.</p>
            <GoThumbsup className='shrink-0 hover:scale-150 active:text-black duration-150 cursor-pointer active:scale-95 text-green-600'/>
            <GoThumbsdown className='shrink-0 hover:scale-150 active:text-black duration-150 cursor-pointer active:scale-95 text-red-600'/>
            
          </div>
          <div id={"resultBody"} className='max-h-full max-w-none text-xs p-4 overflow-scroll scroll-smooth rounded-lg border border-skin-backdrop'>
              <ReactMarkdown 
                className='whitespace-pre-wrap prose-ul:list-disc prose-a:text-sky-500' 
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    if (children.length) {
                      if (children[0] == '▍') {
                        return <span className="animate-pulse cursor-default mt-1">▍</span>
                      }
                      children[0] = (children[0]).replace("`▍`", "▍")
                    }
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline ? (
                      <CodeBlock
                        key={Math.random()}
                        language={(match && match[1]) || ''}
                        value={String(children).replace(/\n$/, '')}
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  table({ children }) {
                    return (
                      <table className="border-collapse border border-black px-3 py-1 dark:border-white">
                        {children}
                      </table>
                    );
                  },
                  th({ children }) {
                    return (
                      <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white">
                        {children}
                      </th>
                    );
                  },
                  td({ children }) {
                    return (
                      <td className="break-words border border-black px-3 py-1 dark:border-white">
                        {children}
                      </td>
                    );
                  },
                }}
              >
                {codeGenResults}
              </ReactMarkdown>
              <div ref={messagesEndRef} />
          </div>
      </div>
    </div>
  )
}

export default GenerativeAIResultSectionv2