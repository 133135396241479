import { useContext } from "react";
import Modal from "react-modal";
import { appContext } from "../../../../App";
import { Avatar } from "@mui/material";
import { FiArrowRight } from "react-icons/fi";


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: "auto",
      width: "auto",
      padding: 0
    },
    overlay: {zIndex: 10000, background: "rgba(0,0,0,0.5)"}

  };

function SwitchAccessModal({modalIsOpen, submitEvent, closeEvent, elId}) {

    const appState = useContext(appContext).state
    const currentAccess = elId.user_role
    const newAccess = currentAccess==="admin" ? "member":"admin"

  return (
    <Modal
    isOpen={modalIsOpen}
    style={customStyles}
    contentLabel="Example Modal" 
    ariaHideApp={false}
    // contentClassName = "border-0"
    className="border-0 absolute"
    >
        <div className={`px-8 py-10 items-center ${appState.theme} bg-skin-primary border border-skin-backdrop rounded-xl text-skin-secondary`}>
            <h1 className=' font-medium text-[24px] gap-1 mb-2'>Change user role</h1>
            <p className=' text-[12px] gap-1 mb-4'>Changing user role changes their access to certain modules.</p>
            <div className="py-4 flex items-center gap-4">
                <Avatar alt={elId.user_id} src={elId.user_avatar ? elId.user_avatar:"data.jpg"} className='w-8 h-8  text-[12px]'/>
                <div>
                    <p className="font-semibold text-skin-widget-text">{elId.user_id}</p>
                    <div className="flex items-center gap-2 ">
                        <p className="line-through">{currentAccess}</p>
                        <FiArrowRight className=""/>
                        <p className="text-sky-600 font-semibold">{newAccess}</p>
                    </div>
                </div>
            </div>
            <div className='flex justify-between mt-10 gap-4'>
                <button onClick={closeEvent}
                className={`rounded-lg px-3 p-1.5 opacity-60 text-[14px] hover:bg-skin-secondary border border-skin-secondary hover:text-skin-primary transition duration-150 cursor-pointer`}>
                    Cancel
                </button>
                <button
                onClick={() => submitEvent(elId.user_mail, newAccess)}
                className={`rounded-lg px-3 p-1.5 text-[14px] text-sky-500 hover:bg-sky-500 border hover:text-skin-primary font-semibold border-sky-500 transition duration-150 cursor-pointer`}>
                    Submit
                </button>
            </div>
        </div>
    </Modal>
  )
}

export default SwitchAccessModal