import React from 'react'
// import {TbApi} from "react-icons/tb"
// import {AiOutlineDeploymentUnit} from "react-icons/ai"
// import {FaDocker} from "react-icons/fa"
import GenerativeTextBox from './GenerativeTextBox'
import { ThreeDots } from 'react-loading-icons'
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from "react-i18next";
import AtomResultCard from './AtomResultCard'

const AtomResultsSection = (props) => {

    const [t] = useTranslation("common");

    return (
        <div className={`h-auto flex flex-1 overflow-scroll justify-around gap-3 transform duration-150 ease-in-out relative mb-4`}>

            {props.searchResults.length > 0 && (
                <>  
                    <div id="results_section" className={`${(props.chatResponse.length===0 && !props.chatResponseLoading) ? "w-[100%]":"w-[75%]"} flex-initial h-full overflow-scroll relative gap-y-2 flex flex-col pr-1`}>
                        {props.searchResults.map((result) => (
                                <AtomResultCard key={result.bot_id} result={result} />
                            )
                          )}
                    </div>
                    
                        {
                            !props.chatResponseLoading ? 
                            (
                                props.chatResponse.length!==0 &&
                                (
                                    <div id="results_section" className='w-[25%]'>
                                        <GenerativeTextBox regenerateEvent = {() => props.generateChat(props.lastSearchData.chatPrompt, true)} ai_response={props.chatResponse} isChatStreamOpen={props.isChatStreamOpen} stopGeneration={props.stopGenerationEvent}/>
                                    </div>
                                )
                                // :
                                // (
                                //     <div id="results_section" className='w-[0%]'>
                                //         <button className='text-sm mb-3 font-normal leading-6 border shadow-inner p-4 rounded-xl border-skin-backdrop'></button>
                                //     </div>
                                // )
                            )
                            :
                            (
                                <div id="results_section" className='w-[25%]'>
                                    <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-20 h-20 w-20' />
                                </div>
                            )
                        }
                </>
            )}
            {props.dataEmpty && (
           <div className='w-full border h-15 bg-gray-100 rounded-lg p-2 text-center items-center'>
                    <p className='text-lg font-semibold'>{t("AtomSearch.No_data_available")}...!</p>
                </div>
            )}
        </div>
    )
}

export default AtomResultsSection