import { createContext, useEffect, useReducer } from 'react';
import { BiSupport } from 'react-icons/bi';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import DeveloperAssistant from './pages/DeveloperAssistant';
import Login from './pages/Login';
import SupportAssistant from './pages/SupportAssistant';
import PrivateRoutes from './pages/PrivateRoutes';
import { getBearerToken } from './utilities/Auth';

export const appContext = createContext()

const AppReducer = (state, action) => {
  
    switch (action.type) {
        case "setTheme":
                return {...state, theme:action.value}
        case "setLoading":
            // window.location.reload(false)
            return {...state, isLoading:action.value}
        case "setLanguage": 
            return {...state, lang:action.value}
        case "setToken":
            return {...state, token:action.value}
        case "setOpenAIToken":
            return {...state, openAiToken:action.value}
        case "setUserDetails":
            return {...state, userDetails:action.value}
        case "setAtomChatResponse":
            return {...state, atomChatResponse:action.value}
        case "setCodeGenResponse":
            return {...state, codeGenResponse:action.value}
        default: {
            return
        }
  }
}

function App() {
    const [appState, appDispatcher] = useReducer(AppReducer,{theme:'theme-light-blue', lang:"US", isLoading:false, token:"", openAiToken:"", userDetails:{}})

    return (
        <appContext.Provider value={{"state":appState,"dispatcher":appDispatcher}}>
            <Router basename='/'>
                <Routes>
                    <Route element={<PrivateRoutes/>}>
                        <Route element={<DeveloperAssistant />} path="/developer" />
                        {/* <Route element={<DeveloperAssistant />} path="/" /> */}
                        <Route element={<SupportAssistant />} path="/support" />
                    </Route>
                    <Route element={<Login />} path="/"/>
                    <Route element={<Login />} path="/login"/>
                </Routes>
            </Router>
        </appContext.Provider>
    );
}

export default App;
