import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import { MdOutlinePersonSearch } from 'react-icons/md'
import SwitchAccessModal from './SwitchAccessModal'
import { CgArrowsExchangeAlt, CgArrowsExchangeAltV } from 'react-icons/cg'
import { getAllUsersAPI, updateUserAccessAPI } from '../../../../utilities/api'
import { useEffect } from 'react'

function UserManagement() {
  const [searchTerm, setSearchTerm] = useState('')
  const [isAccessModalOpen, setAccessModalOpen] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({name: 'Member 1', email: '', picture: ''})

  useEffect(() => {
    get_all_users()
  }, [])

  const get_all_users = async() => {
    const response = await fetch(getAllUsersAPI,  {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
                }
              }
            )
    const data = await response.json()
    setAllUsers(data.users)
  }

  const changeUserAccessEvent = async(user_mail, access_level) => {
    const response = await fetch(updateUserAccessAPI,  {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
        },
        body: JSON.stringify({ email: user_mail, role: access_level })
      }
    )
    const data = await response.json()
    // setAllUsers(data.users)
    const objIndex = allUsers.findIndex((obj => obj.user_mail == user_mail));
    allUsers[objIndex].user_role = access_level
    setAllUsers(allUsers)
    setAccessModalOpen(false)
  }

  return (
    <div className='flex-grow relative flex h-full text-skin-widget-text'>
      {isAccessModalOpen && <SwitchAccessModal modalIsOpen={isAccessModalOpen} submitEvent={changeUserAccessEvent} closeEvent={() => setAccessModalOpen(false)} elId={selectedUser}/> }
        <div className='px-10 w-[100%] lg:w-[70%] relative flex flex-col'>
          <div className='h-[15%] flex items-center justify-between shrink-0'>
            <div className='shrink-0'>
              <p className='text-[11px] pb-1 text-skin-secondary'>Identity Access Management</p>
              <p className=' text-3xl'>All Users</p>
            </div>
            <div className='flex gap-3 items-center'>
              <p className='text-xs px-4 py-2 text-skin-secondary'>{allUsers.length} members</p>
              <div className='flex gap-2 items-center focus-within:border-skin-secondary text-xs px-4 rounded-2xl text-skin-widget-text bg-skin-backdrop border border-skin-backdrop outline-none'>
                <MdOutlinePersonSearch
                className='hover:bg-opacity-75 w-5 h-5 rounded-xl text-skin-accent'
                />

                <input
                type="search" 
                value={searchTerm}  
                onChange = {(event) => setSearchTerm(event.target.value)}
                onKeyDown={event => {
                if (event.key === 'Enter') {
                  setSearchTerm(event.target.value)
                }
                }}  
                autoFocus
                className='outline-none p-3 px-1 text-[12px] bg-transparent' 
                placeholder={`search member...`}/>
              </div>
            </div>
          </div>
          <div className='h-[90%] overflow-scroll pr-4'>
            {allUsers.filter((val) => {
                    if (searchTerm === "") {
                        return val
                    } else if (val.user_id.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return val
                    }
              }).sort(
                (a, b) => a.user_id.localeCompare(b.user_id)
              ).map((user) => (
              <div key={user.id} className='flex items-center justify-between border-t border-skin-backdrop py-3'>
                <div className='flex items-center gap-5'> 
                  <span className={`h-5 w-1 rounded-lg ${user.user_role==="admin" ? "bg-orange-600" :user.user_role==="superadmin" ? "bg-red-600" :"bg-sky-600"}`}></span>
                  <Avatar alt={user.user_id} src={user.user_avatar ? user.user_avatar:"data.jpg"} className='w-7 h-7 text-[12px]' variant="rounded"/>
                  <div className='flex items-center gap-3'>
                    <p className='text-sm font-medium'>{user.user_id}</p>
                    <p className='text-xs text-skin-secondary opacity-60 hidden lg:inline'>
                    {user.user_mail}
                    </p>
                  </div>
                </div>
                {/* <div title='change access' onClick={() => {setSelectedUser(user); setAccessModalOpen(true)}} className='group cursor-pointer flex gap-4 items-center'> */}
                  <button disabled={user.user_role==="superadmin"} title='change access' onClick={() => {setSelectedUser(user); setAccessModalOpen(true)}} className='disabled:pointer-events-none disabled:opacity-60 group cursor-pointer text-[11px] px-4 py-2 rounded-lg text-skin-widget-text bg-skin-backdrop border border-skin-backdrop outline-none w-32 items-center justify-between flex'>
                    {user.user_role}
                    <CgArrowsExchangeAlt className={`${user.user_role==="admin" ? "group-hover:text-red-600":"group-hover:text-green-600"} group-active:scale-95 duration-75 group-hover:scale-125 w-5 h-5`}/>
                  </button>
                {/* </div> */}
              </div>))}
          </div>
        </div>
        <div className='w-[0%] lg:w-[30%] px-2 border-skin-backdrop text-skin-secondary text-sm pr-20 hidden lg:inline  '>
          <div className='h-[15%] flex items-center shrink-0'>
            <p className='font-semibold text-[18px]'>Access Control</p>
          </div>

          <div className='h-[85%] flex flex-col items-center gap-5 shrink-0 overflow-scroll'>
            <div>
              <p>Manage members of your organization and set their access level for the platform.</p>
            </div>
            <div>
              <p className='font-semibold'>Admin Access</p>
              <p>
              Admins have complete access to the platform, including the <span className='text-sky-500'>Console Panel</span>.
              With admin access, you can review, edit, delete, and create resources to continuously improve the platform for all members to use.
              </p>
            </div>
            <div>
              <p className='font-semibold'>Member Access</p>
              <p>Members get access to all our search/AI services, allowing them to find relevant project repositories and EVIDEN reviewed code snippets. Additionally, members can take advantage of our Generative AI capabilities for code generation, translation, explanation, and more.</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default UserManagement