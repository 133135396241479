import { ImpulseSpinner } from "react-spinners-kit";
import { motion } from "framer-motion"


function LoadingScreen({div_position}) {
    return (
      <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
        className='text-skin-primary items-center absolute bg-skin-widget-text bg-opacity-70 h-full w-full z-umax2'
        >
          
          <div className={div_position}>
            <div className='text-center flex flex-col gap-5 items-center'>
            <ImpulseSpinner size={70} frontColor="#0596ff" loading={true} />
                {/* <h1 className='text-skin-accent text-xs'>please wait...</h1> */}
            </div>
          </div>
        </motion.div>
    )
  }

export default LoadingScreen