import { useContext } from "react";
import Modal from "react-modal";
import { appContext } from "../App";


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: "auto",
      width: "auto",
      padding: 0
    },
    overlay: {zIndex: 10000, background: "rgba(0,0,0,0.5)"}

  };

function CodeInputWarning({modalIsOpen, submitEvent, closeEvent}) {
    // console.log(modalIsOpen,submitEvent, closeEvent, elId)
    const appState = useContext(appContext).state
    // console.log(appState)

  return (
    <Modal
    isOpen={modalIsOpen}
    style={customStyles}
    contentLabel="Example Modal" 
    ariaHideApp={false}
    // contentClassName = "border-0"
    className="border-0 absolute outline-none"
    >
        <div className={`p-8 pb-6 items-center ${appState.theme} bg-skin-primary border border-skin-backdrop rounded-md`}>
            <h1 className='text-skin-widget-text font-medium text-[18px] text-center gap-1 mb-2 flex'>Your query seems to contain<p className='text-red-500'>Code</p> in it</h1>
            <h1 className='text-skin-secondary font-medium text-[12px] text-center gap-1 mb-4 justify-center flex'>Are you sure that this doesn't contain client and sensitive data? <br/>Do you want to continue?</h1>
            <div className='flex flex-row-reverse justify-start gap-4'>
                <button
                onClick={submitEvent}
                className={`rounded-md px-3 p-1 text-[14px] text-red-500 hover:bg-red-500 border hover:text-skin-primary font-semibold border-red-500 transition duration-150 cursor-pointer`}
                >Yes</button>
                <button onClick={closeEvent}
                className={`rounded-md px-3 opacity-60 p-1 text-[14px] hover:bg-skin-secondary border border-skin-secondary text-skin-secondary hover:text-skin-primary transition duration-150 cursor-pointer`}
                >No</button>
            </div>
        </div>
    </Modal>
  )
}

export default CodeInputWarning