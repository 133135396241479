import React, { useContext, useState } from 'react'
import { motion } from "framer-motion"
import {CgClose} from "react-icons/cg"
import BackDrop from '../../../utilities/BackDrop'
import ThoughtsTab from './tabs/ThoughtsTab'
import DebugTab from './tabs/DebugTab'
import SupportTicketsTab from './tabs/SupportTicketsTab'


const modalAnimation = {
  hidden:{
      x:"100vh",
      opacity:0
  },
  visible:{
      x: "0",
      opacity: 1,
      transition:{
          duration: 0.05,
          type: "spring",
          damping: 100,
          stiffness: 500,
      }
      
  },
  exit:{
      x:"100vh",
      opacity: 0
  }

}

function ThoughtModal({handleClose, scratch_pad_data, draft_id}) {
    const [toggleState, setToggleStage] = useState(0)
    const toggleTab = (tabIndex) =>{
        setToggleStage(tabIndex)
    }
    const menu_items = [
        {Label : "Explainable AI", Id : 0},
        // {Label : "Debug", Id : 1}, 
        // {Label : "Synonyms", Id : 2}, 
        // {Label : "Variables", Id : 3}, 
        // {Label : "Related Tickets", Id : 4},
      ]

  return (
    <BackDrop onClick={handleClose}>

      <motion.div onClick={(e) => e.stopPropagation()}

      className="absolute right-2 top-[2%] h-[96%] rounded-xl pb-2 w-2/6 bg-skin-primary  overflow-y-scroll scrollbar-hide"
      variants={modalAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
      >
        <header className = 'pt-3 pb-1 sticky top-0 z-umax bg-skin-primary px-2 items-center flex justify-between '>
          <CgClose onClick={handleClose}
            className = {`h-5 w-5 hover:text-red-500 hover:scale-125 transition duration-150 cursor-pointer`}
          />
          <div className='flex flex-col items-end'>
              <div className='w-fit px-3 flex gap-2 items-center text-skin-secondary text-[10px]'>
              <p className='text-[13px] font-bold'>Scratch Pad</p>
            </div>
          </div>
        </header>
        <div className={`px-2 shadow-sm sticky top-[35px] z-umax tabs flex pt-1 bg-skin-primary text-skin-secondary text-opacity-80 text-[10px]`}>
          {menu_items.map(({Label, Id}) => (
            <h1 
            key={Id}
            className={`${toggleState === Id ? "text-skin-accent font-bold border-b-2 border-skin-accent transition duration-150":""} px-2.5 cursor-pointer hover:text-skin-accent`}
            onClick = {() => toggleTab(Id)}
            >{Label}</h1>
          ))}
        </div>        
          {toggleState === 0 && <ThoughtsTab scratch_pad_data={scratch_pad_data} draft_id={draft_id}/>}
          {/* {toggleState === 4 && <SupportTicketsTab scratch_pad_data={scratch_pad_data}/>} */}
      </motion.div>
    </BackDrop>
  )
}

export default ThoughtModal