import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineSnippets } from 'react-icons/ai'
import { CiSearch } from 'react-icons/ci'
import ThreeDots from 'react-loading-icons/dist/esm/components/three-dots'
import SearchSection from '../search_section/SearchSection'
import SnippetResultsSection from './SnippetResultsSection'
import { BsFileEarmarkCode } from 'react-icons/bs'

function SnippetSearchTab({searchQuery, isLoading, snippetSearchResults, chatResponse, chatResponseLoading, isSearchMinimized, selectedTab, onTypeEvent, onSearchEvent, suggestionClickEvent, autoCompleteResults, clearSearch, isMisspelled, spellCorrectedQuery, onSearchKeyDown, autoCompleteLoading}) {
  const [t] = useTranslation("common");

  return (
    <div className={`h-[90%] relative flex ${selectedTab.tabID==="xxx" ? "flex-col-reverse pb-4":"flex-col"}`}>
                        
      <SearchSection selectedTab={selectedTab} isMinimized={isSearchMinimized} query={searchQuery} queryOnChange={onTypeEvent} onSearch={onSearchEvent} onSuggestionClick={suggestionClickEvent} onTypeEvent={onTypeEvent} autoCompleteResults={autoCompleteResults} clearSearch={clearSearch} isMisspelled={isMisspelled} spellCorrectedQuery={spellCorrectedQuery} onKeyDownEvent={onSearchKeyDown} autoCompleteLoading={autoCompleteLoading} chatLoading={chatResponseLoading} chatResponse={chatResponse} isLoading={isLoading}/>
      {false ? <div className={`font-semibold p-3 text-center text-[11px] leading-none duration-100 left-1/2 transform`}>{isSearchMinimized ? "Did you mean ":"No results were found for the given query, did you mean "}<span className='text-skin-accent italic cursor-pointer hover:scale-105 active:scale-95 duration-100' onClick={()=>suggestionClickEvent(spellCorrectedQuery)}>"{spellCorrectedQuery}"</span> ?</div> : (selectedTab.tabID!=="codeGen" && <div className='p-3'></div>)}

        {
            !isLoading ?
                (
                    snippetSearchResults.length !== 0 ?
                    (<SnippetResultsSection searchQuery={searchQuery} searchResults={snippetSearchResults} />)
                :
                (
                    <div className='stroke-skin-accent m-auto h-60 text-[12px] text-skin-secondary mt-10'>
                        <BsFileEarmarkCode className='opacity-40 h-20 w-20 m-auto mb-6'/>
                        <p className=' text-center max-w-[550px]'>{t("Search.Find_EVIDEN_verified_code_snippets_relevant")}!</p>
                        <div className='mt-6 font-medium'>
                        <ul>
                            <li className='flex gap-3 text-skin-accent justify-center items-center'>use the <CiSearch className='h-4 w-4'/> bar to search.</li>
                            <li className='flex gap-3 text-[10px] my-1.5 justify-center items-center'>(or)</li>
                            <li className='flex gap-3 text-skin-accent justify-center items-center'>Switch to Generative AI to use AI to generate relevant code.</li>
                        </ul>
                        </div>
                    </div>
                ))
                :
                (
                    <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-10 h-40' />
                )
        }
    </div>
  )
}

export default SnippetSearchTab