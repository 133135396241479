import React, { useState, useContext } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {IoLogOut} from "react-icons/io5"
import {TbEdit} from "react-icons/tb"
import {AiOutlineUsergroupAdd, AiOutlineFunction} from "react-icons/ai"
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material'
import { blue, deepOrange, grey } from '@mui/material/colors'
import { appContext } from '../../App';
import { logOut } from '../../utilities/utils';


function DropMenu({openOpenAiEditorAction}) {
    const appState = useContext(appContext).state
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    const appDispatcher = useContext(appContext).dispatcher
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
  return (
    <div className={`${appState.theme}`}>
        <Tooltip title="User prefernces">
                <Avatar onClick={handleClick} alt={userDetails.name} sx={{bgcolor: blue[500]}} src={userDetails.picture ? userDetails.picture:"data.jpg"} className='active:scale-95 duration-100 shadow-lg cursor-pointer !w-7 !h-7  !text-[12px]'/>
        </Tooltip>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
            <div onClick={openOpenAiEditorAction} key="8" title='edit' className='flex w-full'>
                <TbEdit className="w-4 h-4 mr-3"/>
                <p className='text-[12px]'>OpenAI key</p>
            </div>
        </MenuItem>
        <MenuItem>
          <div onClick={() => logOut(appDispatcher)} key="9" title="Logout" className='flex text-red-600'>
            <IoLogOut className="w-4 h-4 mr-3"/>
            <p className='text-[12px]'>Logout</p>
          </div>
        </MenuItem>
      </Menu>
      </div>
  )
}

export default DropMenu