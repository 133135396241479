import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getRandID } from '../../../../utilities/utils';
import { CiPaperplane, CiSearch } from 'react-icons/ci'
import { FcApproval } from 'react-icons/fc'
import {VscAdd} from "react-icons/vsc"
import { findRepos, recentRepos } from '../../../../utilities/api';
import { ThreeDots } from 'react-loading-icons';
import RepoCard from './RepoCard';
import RepoEditor from './RepoEditor';


function RepoManagement() {
    const [t] = useTranslation("common")
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"))
    const [query, setQuery] = useState("")
    const [recentRepoDetails, setRecentRepoDetails] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [searchLoading, setSearchLoading] = useState(false)
    const [editType, setEditType] = useState(null)
    const [activeRepo, setActiveRepo] = useState({})
    const [recentsLoading, setRecentsLoading] = useState(true)
    const [isReadOnly, setReadOnly] = useState(false)

    useEffect(() => {
        getAllRepositories()
      }, [])
  
      const getAllRepositories = () => {
          fetch(recentRepos, {
              method:"GET",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
              },
          }).then((response) => {
              if (response.ok) { return response.json(); }
          })
          .then((json) => {
              if (json.status === 'success') {
                    setRecentRepoDetails(json.ai_response)
                    setRecentsLoading(false)
              }
          })
          .catch((error) => {
              console.log(error);
          });
          console.log("search function for code repo search")
      }
  
      const editRepoEvent = (val) => {
          setActiveRepo(val)
          setEditType("edit")
          setReadOnly(false)
      }
  
      const viewRepoEvent = (val) => {
          setActiveRepo(val)
          setEditType("view")
          setReadOnly(true)
      }
  
      const searchEvent = (val) => {
          setSearchLoading(true)
          fetch(findRepos, {
              method:"POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
              },
              body: JSON.stringify({ query: val })
          }).then((response) => {
              if (response.ok) { return response.json(); }
          })
          .then((json) => {
              if (json.status === 'success') {
                  setSearchResults(json.ai_response.search_results)
                  setTotalResults(json.ai_response.total_hits)
                  setSearchLoading(false)
              }
          })
          .catch((error) => {
              console.log(error);
          });
          console.log("search function for code repo search")
      }
  
      const addRepoEvent = (val) => {
          const new_repo_template = {
            bot_id: "atom"+getRandID(),
            bot_name: "",
            bot_summary: "",
            category: "",
            sub_category: "",
            tags: [],
            status: true,
            how_to_use: "",
            how_to_extend_and_customize: "",
            used_for: "",
            source_code: "",
            demo_link: "",
            api_docs: "",
            docker_image: "",
            author: userDetails.name,
            creation_date: new Date().toISOString(),
          }
          setEditType("add")
          setActiveRepo(new_repo_template)
          setReadOnly(false)
      }
      const cancelEdit = () => {
          console.log(
              "cancel called"
          )
          setActiveRepo({})
          setEditType(null)
      }
  
      const updateComponentsOnSave = (val) => {
          var index = recentRepoDetails.findIndex(x => x.bot_id==val.bot_id); 
          // here you can check specific property for an object whether it exist in your array or not
          if (index !== -1) {
              recentRepoDetails.splice(index, 1)
          }
          const newList = [val, ...recentRepoDetails]
          setRecentRepoDetails(newList)
  
          var s_index = searchResults.findIndex(x => x.bot_id==val.bot_id); 
          // here you can check specific property for an object whether it exist in your array or not
          if (s_index !== -1) {
              searchResults.splice(s_index, 1)
              const newSresults = [val, ...searchResults]
              setSearchResults(newSresults)
          }
  
          setActiveRepo({})
          setEditType(null) 
      }
      const updateComponentsOnDelete = (val) => {
          setRecentRepoDetails((repos) => repos.filter((snip) => snip.bot_id != val.bot_id))
          setSearchResults((repos) => repos.filter((snip) => snip.bot_id != val.bot_id))
          if (activeRepo.bot_id === val.bot_id){
              setActiveRepo({})
              setEditType(null) 
          }    
      }
  return (
    <div className='flex-grow relative flex h-full pr-1'>
        <div className='w-[30%] flex flex-col border-skin-backdrop p-1 pb-0'>
            <div className='h-10 flex items-center justify-between px-2 gap-3'>
                <p className='text-skin-secondary font-semibold flex-grow'>{"Manage Atom Repositories"}</p>
                <>
                    {/* <SlRefresh className={`h-5 w-5`}/> */}
                    {searchResults.length>0 && <p className='text-[12px] hover:underline underline-offset-1 hover:text-skin-accent active:scale-95 duration-150 cursor-pointer' onClick={() => {setSearchResults([]); setTotalResults(0)}}>{t("AdminConsole.clear_results")}</p>}
                    <button 
                    disabled = {(editType==="add" && activeRepo!=={})}
                    className={`disabled:opacity-50 disabled:cursor-not-allowed hover:border-skin-accent hover:text-skin-accent active:scale-90 duration-100 flex items-center gap-2 border rounded-md px-2 py-1 text-sm text-skin-secondary border-skin-secondary`}
                    onClick={() => addRepoEvent(getRandID())}
                    >
                        <VscAdd className='text-skin-accent'/>
                        <p className='hidden lg:block'>{t("AdminConsole.Add")}</p>
                    </button>
                </>
            </div>
            <div className='overflow-clip border rounded-full p-2 h-10 m-2 mx-4 border-skin-backdrop flex gap-2 px-2 items-center bg-skin-primary focus-within:border-skin-accent'>
                <CiSearch 
                onClick={() => console.log(query)}
                className='hover:bg-opacity-75 w-6 h-6 rounded-xl text-skin-accent'
                />

                <input
                type="text" 
                value={query}  
                onChange = {(event) => setQuery(event.target.value)}
                onKeyDown={event => {
                if (event.key === 'Enter') {
                    searchEvent(query)
                }
                }}
                autoFocus  
                className='outline-none p-3 px-1 flex-grow text-[12px] bg-transparent' 
                placeholder={`${t('AdminConsole.Search_here_to_find_your_repo')}...`}/>

                <CiPaperplane 
                        onClick={() => searchEvent(query)}
                        className={`cursor-pointer hover:scale-110 text-skin-accent active:scale-95 w-6 h-6 transition duration-100`}
                        />
            </div>
            <div className='flex flex-grow flex-col gap-3 px-2 mt-1 overflow-scroll'>
                {
                    !searchLoading ?
                    (
                        searchResults.length!==0 ? 
                        (
                            <>
                                <p className='sticky top-0 z-10 bg-skin-primary text-center text-[10px] pb-1'>{t("AdminConsole.showing")} {searchResults.length} of {totalResults} {t("AdminConsole.Search_Results")}</p>
                                {searchResults.map((val) => (
                                    <RepoCard repoDetails={val} editEvent={editRepoEvent} deleteEvent={updateComponentsOnDelete} key={val.bot_id} viewRepoEvent={viewRepoEvent}/>
                                ))}
                            </>
                        ):(
                            <div className='m-auto mt-20 text-xs text-center'>
                                <p>{t("AdminConsole.Enter_Keyword_repo")}.</p>
                            </div>
                        )
                    )
                        :
                    (
                        <div>
                            <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-20 h-20 w-20' />
                        </div>
                    )
                }
            </div>
            <div className='flex mb-2 mx-2 p-3 flex-col gap-3 px-2 mt-10 overflow-scroll border border-skin-accent rounded-md min-h-[230px]'>
                {!recentsLoading?
                    (
                        <>
                            <p className='text-center text-[10px]'>{t("AdminConsole.Recently_Added")}/{t("AdminConsole.Modified_Repos")} ...</p>
                            {recentRepoDetails.slice(0,3).map((val) => (
                                <RepoCard repoDetails={val} editEvent={editRepoEvent} deleteEvent={updateComponentsOnDelete} key={val.bot_id} viewRepoEvent={viewRepoEvent}/>
                            ))}
                        </>
                    )
                        :
                    (
                        <div>
                            <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-10 h-20 w-20' />
                        </div>
                    )
                }
            </div>
            {/* <div className='h-10 px-3 flex text-xs gap-8 justify-between items-center '>
                <div className='text-[10px]'>Showing 5 out of 84</div>
                <div className='flex gap-2'>
                    <div className='border border-skin-backdrop rounded-md p-1 px-2'>previous</div>
                    <div className='border border-skin-backdrop rounded-md p-1 px-2'>next</div>
                </div>
            </div> */}
        </div>
            {editType!==null ? 
            (
                <RepoEditor key={activeRepo.bot_id} setRecents={updateComponentsOnSave} repoData={activeRepo} editType={editType} cancelFormEvent={cancelEdit} isReadOnly={isReadOnly}/>
            ) 
                :
            (
                <div className='w-[70%] flex flex-col rounded-t-md overflow-clip mr-1 border border-b-0 border-skin-secondary border-opacity-30'>
                    <div className='max-w-[500px] stroke-skin-accent m-auto h-60 text-[12px] text-skin-secondary mt-20'>
                        <FcApproval className=' h-20 w-20 m-auto mb-6'/>
                        {/* <p className=' text-center max-w-[550px]'>Best practices:</p> */}
                        <div className='mb-8'>
                            <ul>
                                <li className='flex gap-3 text-skin-accent justify-center items-center'>{t("AdminConsole.Click_on_Add_button_to_publish_a_new_repo")}.</li>
                                <li className='flex gap-3 text-[8px] my-1.5 justify-center items-center'>({t("Search.or")})</li>
                                <li className='flex gap-3 text-skin-accent justify-center items-center'>{t("AdminConsole.Make_changes_to_an_existing_repo_by_clicking_the_edit_button")}</li>
                            </ul>
                        </div>
                        <div className='text-center'>
                            <p className='font-extrabold underline underline-offset-1 mb-2'>{t("AdminConsole.Best_practices")}:</p>
                            <ol className='list-disc list-inside text-[10px]'>
                                <li>{t("AdminConsole.Avoid_including_any_sensitive_information")}.</li><br/>
                                <li>{t("AdminConsole.Use_secure_coding_practices")}, {t("AdminConsole.such_as_validating_user_input")}, {t("AdminConsole.sanitizing_data")}, {t("AdminConsole.and_avoiding_common_vulnerabilities_such_as_SQL_injection_and_cross-site_scripting")} (XSS).</li><br/>
                                <li>{t("AdminConsole.Only_upload_code_repos")}.</li><br/>
                                <li>{t("AdminConsole.Write_comments_that_explain_the_purpose_of_the_code_repo")}.</li>
                            </ol>
                        </div>
                        
                    </div>
                </div>
            )}
    </div>
  )
}

export default RepoManagement