import React from 'react'
import { Fade, Tooltip } from '@mui/material'
import {CgPushLeft} from "react-icons/cg"

function SideBar({menu, isMenuOpen, setMenuOpen, toggleTab, toggleState}) {
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"))
  return (
    <span className={`${(isMenuOpen) ? "w-48":"w-[49px]"} shrink-0 border-r border-skin-backdrop group transform duration-150 ease-in-out relative p-2 bg-skin-primary`}>
              <CgPushLeft className={`absolute text-skin-accent cursor-pointer left-4 bottom-6 w-5 h-5 ${!isMenuOpen && "rotate-180"}`}
                  onClick={() => setMenuOpen(!isMenuOpen)}
                  />    
              <div className='flex gap-2 flex-col'>
                {menu.map(({menu_id, title, key, Icon, access}) => (
                  access.includes(userDetails.user_role) && (<Tooltip key={key} placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={title}>
                    <div 
                      onClick = {() => toggleTab(menu_id)}
                      key={key} 
                      className={`${toggleState === menu_id ? "bg-skin-accent text-skin-primary":"hover:bg-skin-backdrop text-skin-secondary"} transition flex rounded-lg p-2 cursor-pointer items-center gap-x-4`}>   
                      <Icon/>
                      <span className={` ${isMenuOpen ? "" : "hidden"} overflow-hidden whitespace-nowrap text-xs origin-left transition-200`}>
                        {title}
                      </span>
                    </div>
                  </Tooltip>)
              ))}
              </div>
    </span>
  )
}

export default SideBar