import React from 'react'
import { useTranslation } from 'react-i18next';
import { CiSearch } from 'react-icons/ci';
import { VscRepoForked } from 'react-icons/vsc';
import ThreeDots from 'react-loading-icons/dist/esm/components/three-dots';
import SearchSection from '../search_section/SearchSection';
import AtomResultsSection from './AtomResultsSection';

function AtomSearchTab({searchQuery, isLoading, atomSearchResults, chatResponse, generateChat, lastRepoSearchData, chatResponseLoading, isChatStreamOpen, stopChatGeneration, isSearchMinimized, selectedTab, onTypeEvent, onSearchEvent, suggestionClickEvent, autoCompleteResults, clearSearch, isMisspelled, spellCorrectedQuery, onSearchKeyDown, autoCompleteLoading}) {
  const [t] = useTranslation("common");
  
  return (
    <div className={`h-[90%] relative flex ${selectedTab.tabID==="xxx" ? "flex-col-reverse pb-4":"flex-col"}`}>
                        
      <SearchSection selectedTab={selectedTab} isMinimized={isSearchMinimized} query={searchQuery} queryOnChange={onTypeEvent} onSearch={onSearchEvent} onSuggestionClick={suggestionClickEvent} onTypeEvent={onTypeEvent} autoCompleteResults={autoCompleteResults} clearSearch={clearSearch} isMisspelled={isMisspelled} spellCorrectedQuery={spellCorrectedQuery} onKeyDownEvent={onSearchKeyDown} autoCompleteLoading={autoCompleteLoading} chatLoading={chatResponseLoading} chatResponse={chatResponse} isLoading={isLoading}/>
      {false ? <div className={`font-semibold p-3 text-center text-[11px] leading-none duration-100 left-1/2 transform`}>{isSearchMinimized ? "Did you mean ":"No results were found for the given query, did you mean "}<span className='text-skin-accent italic cursor-pointer hover:scale-105 active:scale-95 duration-100' onClick={()=>suggestionClickEvent(spellCorrectedQuery)}>"{spellCorrectedQuery}"</span> ?</div> : (selectedTab.tabID!=="codeGen" && <div className='p-3'></div>)}
      {!isLoading ?
          (
              atomSearchResults.length !== 0 ?
                  (
                      <AtomResultsSection chatLoading={chatResponseLoading} searchQuery={searchQuery} searchResults={atomSearchResults} chatResponse={chatResponse} generateChat={generateChat} lastSearchData={lastRepoSearchData} chatResponseLoading={chatResponseLoading} isChatStreamOpen={isChatStreamOpen} stopGenerationEvent={stopChatGeneration}/>
                  )
                  :
                  (
                      <div className='stroke-skin-accent m-auto h-60 text-[12px] text-skin-secondary mt-10'>
                          <VscRepoForked className='opacity-40 h-20 w-20 m-auto mb-6' />
                          <p className=' text-center max-w-[550px]'>{t("Search.EVIDEN_developer")}. <br />{t("Search.find_code_api")}.</p>
                          <div className='mt-6 font-medium'>
                              <ul>
                                  <li className='flex gap-3 text-skin-accent justify-center items-center'>{t("Search.use_the")} <CiSearch className='h-4 w-4' /> {t("Search.search_code")}.</li>
                                  <li className='flex gap-3 text-[10px] my-1.5 justify-center items-center'>({t("Search.or")})</li>
                                  <li className='flex gap-3 text-skin-accent justify-center items-center'>{t("Search.switch_snippets")}.</li>
                              </ul>
                          </div>
                      </div>
                  )
          )
          :
          (
              <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-10 h-40' />
          )
          }
    </div>
  )
}

export default AtomSearchTab