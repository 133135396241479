import React, { useContext, useState } from 'react'

import { Bounce, toast } from 'react-toastify';
import { BiExpandAlt } from 'react-icons/bi';
import { IoCopyOutline } from 'react-icons/io5';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import { atomDark, prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { GoThumbsdown, GoThumbsup } from 'react-icons/go';
import { LinearProgress } from '@mui/material';
import { TiTick } from 'react-icons/ti';
import { appContext } from '../../../App';

function CodeResultCard({result}) {
  const [isExpand, setExpand] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const appState = useContext(appContext).state

  console.log(result.score)
  const copyClick = () => {
    setIsCopied(true)
    // toast.success(`Copied to clipboard!`, {theme: "colored", className:"", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
    setTimeout(() => {
        setIsCopied(false)
      }, 2000)
  }
  
  return (
    <div className="border shrink-0 border-skin-backdrop rounded-lg p-4 px-6 flex flex-col gap-2 transform ease-in-out duration-150">
        <div className="flex items-center justify-between relative overflow-scroll scrollbar-hide">
            <div className='w-[45%] leading-none truncate'>
                <p className='text-[8px]'>Name</p>
                <p className='text-xs' dangerouslySetInnerHTML={{__html:result.name.replaceAll("<b>", "<b><u>").replaceAll("</b>", "</u></b>")}}/>{/* .replaceAll("<span>", "<span style='color: #002d3c;'>") */}
            </div>
            <div className='w-[15%] leading-none truncate'>
                <p className='text-[8px]'>Language</p>
                <p className='text-xs font-semibold'>{result.lang}</p>
            </div>
            <div title={Math.round(result.score*100).toString()+"%"} className={`w-[15%] leading-none truncate ${result.score<=0.8 ? "text-yellow-600":"text-green-600"}`}>
                <p className='text-[8px] mb-2 text-skin-secondary'>Relevance</p>
                <LinearProgress variant="determinate" className='rounded-full' value={result.score*100} color="inherit"/>
            </div>
            <div className='w-[25%] flex justify-end items-center gap-3 text-skin-accent'>
                <GoThumbsup className='hover:scale-125 active:text-black duration-100 cursor-pointer active:scale-95 text-green-600'/>
                <GoThumbsdown className='hover:scale-125 active:text-black duration-100 cursor-pointer active:scale-95 text-red-600 mr-3'/>
                <CopyToClipboard text={result.code}>
                    <button 
                    className={`${isCopied? "bg-green-500 border-skin-backdrop text-skin-primary":"hover:text-skin-primary hover:bg-skin-accent border-skin-accent"} active:scale-95 transform duration-150 ease-in-out rounded-md text-[10px] px-2 p-1 border flex gap-2 items-center`}
                    onClick={copyClick}
                    >
                        {isCopied? <TiTick className="h-4 w-4"/> :<IoCopyOutline className="h-4 w-4"/>}
                        <span className=''>{isCopied ? "Copied":"Copy"}</span>
                    </button>
                </CopyToClipboard>
                <button onClick={() => setExpand(!isExpand)} className="hover:bg-skin-backdrop rounded-md text-[10px] px-2 p-1 border border-skin-accent flex gap-2 items-center">
                    <BiExpandAlt className="h-4 w-4"/>
                    <span>{isExpand? "Collapse":"Expand"}</span>
                </button>
            </div>
        </div>
        <div>
            <div className='leading-none'>
                <p className='text-[10px]' dangerouslySetInnerHTML={{__html:result.description}}/>{/* .replaceAll("<span>", "<span style='color: #002d3c;'>") */}
            </div>
        </div>
        <div>
            <SyntaxHighlighter wrapLongLines className={`${isExpand ? "h-full":"h-52"} !text-xs overflow-scroll rounded-md transform duration-150 ease-in-out`} language={result.lang.toLowerCase()} style={appState.theme === "theme-dark-blue" ? atomDark:prism}>
                {result.code}
            </SyntaxHighlighter>
        </div>
    </div>
  )
}

export default CodeResultCard