import React, { KeyboardEventHandler, useState } from 'react';

import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});


function MultiSelectSearch({value, setValue}) {
  // console.log(value)
    const [inputValue, setInputValue] = useState('');
    // const [value, setValue] = useState([]);
  
    const handleKeyDown = (event) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue('');
          event.preventDefault();
      }
    };
  
    return (
      <CreatableSelect
        components={components}
        id="tags-field"
        inputValue={inputValue}
        isClearable
        isMulti
        required
        menuIsOpen={false}
        onChange={(newValue) => setValue(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Enter tags"
        value={value}
        // unstyled
        // className="text-sm"
        classNames={{
          input: (state) => "!text-xs !text-skin-secondary",
          control: (state) => "!border !border-skin-backdrop !bg-skin-primary !text-skin-secondary rounded-md !text-sm",
        }}
      
      />
    );
  };
export default MultiSelectSearch