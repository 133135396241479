import React, { useEffect, useRef, useState } from 'react'

import {motion} from 'framer-motion'
import { Avatar, Fade, Tooltip } from '@mui/material'
import * as DOMPurify from 'dompurify';
import { ThreeDots } from 'react-loading-icons'
import { blue } from '@mui/material/colors';
import { MdDoneAll, MdOutlineGroups2, MdOutlineMoreTime, MdOutlineQuestionMark } from 'react-icons/md';
import { TiLightbulb } from 'react-icons/ti';
import ReactMarkdown from 'react-markdown'
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeRaw from 'rehype-raw';
import { RiArrowDropDownLine, RiArrowDropUpLine, RiEditCircleFill, RiEditCircleLine } from 'react-icons/ri';
import { BsExclamationLg } from 'react-icons/bs';
import { BiSupport } from 'react-icons/bi';
import { HiOutlineTicket } from 'react-icons/hi2';
import {TbSquareRoundedLetterA, TbSquareRoundedLetterB, TbSquareRoundedLetterC, TbSquareRoundedNumber1, TbSquareRoundedNumber2, TbSquareRoundedNumber3} from 'react-icons/tb'

const animation = {
    hidden:{
      scale:.8,
      opacity: 0
    },
    visible:{
      scale:1,
      opacity: 1,
      transition:{
        delay:0.1
      }
    }
}

function UserResponse({query}) {
    return (
      <motion.div initial="hidden" animate="visible" variants={animation}>
          <div className='px-2 flex flex-row-reverse gap-3 ml-2 my-2'>
              <Avatar className='!w-6 !text-[12px] !h-6' alt={query.user_name} src={query.avatar ? query.avatar : "data.jpg"} /> 
              <p className='max-w-[60%] p-4 py-2 bg-skin-accent text-skin-backdrop rounded-xl shadow-sm rounded-tr-md text-[13px] font-medium text-left' dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(query.label)}}/>
          </div>
      </motion.div>
    )
}

function DraftResponse({draft, agentInfo, setThoughtsOpen}) {
    console.log(draft.cited_sources)
    return (
        <div className='p-4 py-3 relative max-w-[65%] bg-skin-backdrop bg-opacity-80 text-skin-widget-text rounded-tl-md rounded-xl shadow-sm text-[13px] font-light'>
                <ReactMarkdown 
                className='prose-ul:list-disc prose-ol:list-decimal prose-a:text-sky-500' 
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax, rehypeRaw]}
                components={{
                    em: ({ node, inline, className, children, ...props }) => {
                        if (children[0] && typeof children[0] === 'string' && children[0].startsWith('^')) {
                            return <sup>{children[0].substring(1)}</sup>
                        }
                        if (children[0] && typeof children[0] === 'string' && children[0].startsWith('~')) {
                            return <sub>{children[0].substring(1)}</sub>
                        }
                        return <i {...props} />
                    }
                }}>
                    {DOMPurify.sanitize(draft.chat_response)}
                </ReactMarkdown>
                {(draft.cited_sources.length!==0) && 
                <div className='mt-8'>
                    <p>Citations:</p>
                    <div className='flex gap-2 flex-wrap'>
                        {draft.cited_sources.map(({id, title, url}) => (
                            <a href={url} className='text-sky-600 border border-sky-600 rounded-md text-[10px] px-2' key={id}>{id}. {title}</a>
                        ))}
                    </div>
                </div>
                }
            </div>
    )}
    
function Drafts({resp_id, drafts, agentInfo, setThoughtsOpen}) {
    const [draft, setDraft] = useState(1)
    return (
        <div className=''>
            <div className='flex gap-2 items-center ml-11 my-2'>
                <p className='text-[10px] font-semibold'>Drafts</p>
                <Tooltip placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"draft A"}>
                    <span onClick={() => setDraft(1)}>
                        <TbSquareRoundedLetterA className={`${draft===1 ? "text-skin-accent":"text-skin-secondary text-opacity-30"} w-4 h-4 hover:text-skin-accent hover:scale-110 transform duration-100 active:scale-90 ease-in-out cursor-pointer`}/>
                    </span>
                </Tooltip>
                <Tooltip placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"draft B"}>
                    <span onClick={() => setDraft(2)}>
                        <TbSquareRoundedLetterB className={`${draft===2 ? "text-skin-accent":"text-skin-secondary text-opacity-30"} w-4 h-4 hover:text-skin-accent hover:scale-110 transform duration-100 active:scale-90 ease-in-out cursor-pointer`}/>
                    </span>
                </Tooltip>
                <Tooltip placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"draft C"}>
                    <span onClick={() => setDraft(3)}>
                        <TbSquareRoundedLetterC className={`${draft===3 ? "text-skin-accent":"text-skin-secondary text-opacity-30"} w-4 h-4 hover:text-skin-accent hover:scale-110 transform duration-100 active:scale-90 ease-in-out cursor-pointer`}/>
                    </span>
                </Tooltip>
            </div>
            {drafts[draft].search_results.length!==0 && <SourceCard response={{sources:drafts[draft].search_results}}/>}
            <div className='group pl-2 flex gap-3 mr-2 items-start'>
                <div className='flex flex-col gap-3 items-center'>
                    <Avatar className='!w-6 !text-[12px] !h-6' alt={agentInfo.agent_name} sx={{ bgcolor: blue[600] }} src={agentInfo.avatar ? agentInfo.avatar:"data.jpg"} /> 
                    <TiLightbulb onClick={() => setThoughtsOpen(resp_id, draft)} className='w-4 h-4 text-yellow-500 cursor-pointer active:scale-95 duration-100 hover:text-indigo-500'/> 
                </div>
                <DraftResponse draft={drafts[draft]} agentInfo={agentInfo} setThoughtsOpen={setThoughtsOpen}/>
            </div>
            {drafts[draft].scratch_pad.related_tickets.length!==0 && <TicketsCard response={{tickets:drafts[draft].scratch_pad.related_tickets}}/>}
        </div>
    )}

function StreamResponse({response, agentInfo, setThoughtsOpen}) {
    return (
        <div className='group pl-2 flex gap-3 mr-2 my-1'>
            <div className='flex flex-col gap-3 items-center'>
                <Avatar className='!w-6 !text-[12px] !h-6' alt={agentInfo.agent_name} sx={{ bgcolor: blue[600] }} src={agentInfo.avatar ? agentInfo.avatar:"data.jpg"} /> 
            </div>
            <div className='p-4 py-3 relative max-w-[65%] bg-skin-backdrop bg-opacity-80 text-skin-widget-text rounded-tl-md rounded-xl shadow-sm text-[13px] font-light'>
                {response.chat==="" ? 
                <ThreeDots fill="transparent" className='stroke-skin-accent w-10' />
                :
                <ReactMarkdown 
                className='prose-ul:list-disc prose-ol:list-decimal prose-a:text-sky-500' 
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax, rehypeRaw]}
                components={{
                    em: ({ node, inline, className, children, ...props }) => {
                        if (children[0] && typeof children[0] === 'string' && children[0].startsWith('^')) {
                            return <sup>{children[0].substring(1)}</sup>
                        }
                        if (children[0] && typeof children[0] === 'string' && children[0].startsWith('~')) {
                            return <sub>{children[0].substring(1)}</sub>
                        }
                        return <i {...props} />
                    }
                }}>
                    {DOMPurify.sanitize(response.chat)}
                </ReactMarkdown>
                }
                {(response.citations.length!==0) && 
                <div className='mt-8'>
                    <p>Citations:</p>
                    <div className='flex gap-2 flex-wrap'>
                        {response.citations.map(({id, title, url}) => (
                            <a href={url} className='text-sky-600 border border-sky-600 rounded-md text-[10px] px-2' key={id}>{id}. {title}</a>
                        ))}
                    </div>
                </div>
                }
            </div>
        </div>
    )}
function BotResponse({response, agentInfo, setThoughtsOpen}) {
    const [text, setText] = useState("")
    const [fullText, setFullText] = useState(response.chat_response)
    const [index, setIndex] = useState(0)
    const [isGenerating, setIsGenerating] = useState(true)
    // useEffect(() => {
    //     if (index < fullText.length) {
    //       setTimeout(() => {
    //         setText(text + fullText[index])
    //         setIndex(index + 1)
    //         response.renderCompleteAction()
    //       }, 0.3)
    //     }
    //     else {
    //         setIsGenerating(false)
    //     }
    //   }, [index])
    return (
        <div className='group pl-2 flex gap-3 mr-2 my-1'>
            <div className='flex flex-col gap-3 items-center'>
                <Avatar className='!w-6 !text-[12px] !h-6' alt={agentInfo.agent_name} sx={{ bgcolor: blue[600] }} src={agentInfo.avatar ? agentInfo.avatar:"data.jpg"} /> 
                <TiLightbulb onClick={setThoughtsOpen} className='w-4 h-4 text-yellow-500 cursor-pointer active:scale-95 duration-100 hover:text-indigo-500'/> 
                <Tooltip placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"draft A"}>
                    <span>
                        <TbSquareRoundedLetterA classtext-skin-accentNamtext-skin-secondary text-opacity-30e='w-4 h-4 hover:text-skin-accent hover:scale-110 transform duration-100 active:scale-90 ease-in-out cursor-pointer'/>
                    </span>
                </Tooltip>
                <Tooltip placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"draft B"}>
                    <span>
                        <TbSquareRoundedLetterB classtext-skin-accentNamtext-skin-secondary text-opacity-30e='w-4 h-4 hover:text-skin-accent hover:scale-110 transform duration-100 active:scale-90 ease-in-out cursor-pointer'/>
                    </span>
                </Tooltip>
                <Tooltip placement="right" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"draft C"}>
                    <span>
                        <TbSquareRoundedLetterC classtext-skin-accentNamtext-skin-secondary text-opacity-30e='w-4 h-4 hover:text-skin-accent hover:scale-110 transform duration-100 active:scale-90 ease-in-out cursor-pointer'/>
                    </span>
                </Tooltip>
            </div>
            <div className='p-4 py-3 relative max-w-[65%] bg-skin-backdrop bg-opacity-80 text-skin-widget-text rounded-tl-md rounded-xl shadow-sm text-[13px] font-light'>
                <ReactMarkdown 
                className='prose-ul:list-disc prose-ol:list-decimal prose-a:text-sky-500' 
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax, rehypeRaw]}
                components={{
                    em: ({ node, inline, className, children, ...props }) => {
                        if (children[0] && typeof children[0] === 'string' && children[0].startsWith('^')) {
                            return <sup>{children[0].substring(1)}</sup>
                        }
                        if (children[0] && typeof children[0] === 'string' && children[0].startsWith('~')) {
                            return <sub>{children[0].substring(1)}</sub>
                        }
                        return <i {...props} />
                    }
                }}>
                    {DOMPurify.sanitize(fullText)}
                </ReactMarkdown>
                {(!isGenerating && response.citations.length!==0) && 
                <div className='mt-8'>
                    <p>Citations:</p>
                    <div className='flex gap-2 flex-wrap'>
                        {response.citations.map(({id, title, url}) => (
                            <a href={url} className='text-sky-600 border border-sky-600 rounded-md text-[10px] px-2' key={id}>{id}. {title}</a>
                        ))}
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

function SourceCard({response}) {
    return (
        <div className='flex flex-wrap gap-1 ml-11 my-3 text-[10px] text-skin-accent items-center'>
            <p className=''>Search results</p>
            {response.sources.map((val) => (
                <a href={val._source.source} target='_blank' key={val._source.id} className='border border-skin-backdrop text-sky-600 underline rounded-md px-1.5'>{val._source.instruction}</a>
            ))}
        </div>
    )
}
const Card = ({isOpen, ticket, handleTicketToggle}) => {
    return(
        <div onClick={handleTicketToggle} className='cursor-pointer duration-200 flex items-center gap-2 border hover:bg-skin-backdrop border-skin-accent text-skin-accent rounded-md px-2 py-1'>
            <p key={ticket.ticket_number} className=''>{ticket.short_description}</p>
            {
                !isOpen ? <RiArrowDropDownLine className='w-4 h-4 text-skin-accent'/> : <RiArrowDropUpLine className='w-4 h-4 text-skin-accent'/>
            }
        </div>
    )
}
function TicketsCard({response}) {
    const [openedTicket, setOpenedTicket] = useState(null)
    const [isTicketOpen, setIsTicketOpen] = useState(new Array(response.tickets.length).fill(false))
    const handleTicketToggle = (ticket, isOpen, idx) => {
            setOpenedTicket(ticket)
            let _temp = new Array(response.tickets.length).fill(false)
            _temp[idx] = !isOpen
            setIsTicketOpen(_temp)
            // if (!isOpen) {
            //     response.renderCompleteAction()
            // }
    }
    
    const flagRef = useRef(null)
    flagRef.current = isTicketOpen
    return (
        <div className='ml-11 my-3 text-[10px] text-skin-accent flex flex-col gap-2 max-w-[65%]'>
            {response.tickets.length!==0 &&
            <>
            <div className='flex flex-col gap-1 items-start'>
                <p className='mr-2 font-semibold'>Related Tickets</p>
                <div className='flex items-center gap-1'>
                {response.tickets.map((val, idx) => (
                    <Card key={idx} ticket={val} isOpen={flagRef.current[idx]} handleTicketToggle={() => handleTicketToggle(val, flagRef.current[idx], idx)}/>
                ))}
                </div>
            </div>
            {
            <div className={`${(openedTicket!==null && isTicketOpen.some(Boolean)) ? "flex flex-col border border-skin-accent rounded-xl gap-2 overflow-clip mr-2":" h-0"} ease-in-out duration-150 transform`}>
                {(openedTicket!==null && isTicketOpen.some(Boolean)) && <>
                <div className='bg-skin-accent flex justify-between items-center pt-2 pb-1.5 px-6 overflow-scroll whitespace-nowrap ease-in-out duration-150 transform'>
                    <Tooltip placement="bottom" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"Ticket Number"}>
                        <div className='text-skin-primary flex gap-2 items-center'>
                            <HiOutlineTicket className="h-4 w-4"/>
                            <p>
                                {openedTicket.ticket_number}
                            </p>
                        </div>  
                    </Tooltip>
                    <Tooltip placement="bottom" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"Assigned To"}>
                        <div className='text-skin-primary flex gap-2 items-center'>
                            <BiSupport className='h-4 w-4'/>
                            <p>
                                {openedTicket.assigned_to}
                            </p>
                        </div>
                    </Tooltip>
                    <Tooltip placement="bottom" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"Opened On"}>
                        <div className='text-skin-primary flex gap-2 items-center'>
                            <MdOutlineMoreTime className='h-4 w-4'/>
                            <p>
                                {openedTicket.opened_on.split(" ")[0]}
                            </p>
                        </div>
                    </Tooltip>
                    <Tooltip placement="bottom" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"Updated On"}>
                        <div className='text-skin-primary flex gap-2 items-center'>
                            <RiEditCircleLine className='h-4 w-4'/>
                            <p>
                                {openedTicket.updated_on.split(" ")[0]}
                            </p>
                        </div>
                    </Tooltip>
                    <Tooltip placement="bottom" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"Assignment Group"}>
                        <div className='text-skin-primary flex gap-2 items-center'>
                            <MdOutlineGroups2 className='h-4 w-4'/>
                            <p>
                                {openedTicket.assignment_group}
                            </p>
                        </div>
                    </Tooltip>
                    <Tooltip placement="bottom" disableInteractive TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} title={"Priority"}>
                        <div className='text-skin-primary flex gap-2 items-center'>
                            <BsExclamationLg className={`${openedTicket.priority ==="High" ? "text-red-500" : openedTicket.priority==="Low" ? "text-green-500":"text-yellow-500"} h-4 w-4`}/>
                        </div>
                    </Tooltip>
                </div>
                <div className='px-4 pb-2 gap-2 justify-between flex-row-reverse flex'>
                    <div className='gap-2 items-center'>
                        <p className='text-skin-accent font-medium'>resolution summary</p>
                        <p className='text-skin-secondary'>{openedTicket.resolution_summary}</p>
                    </div>
                    <div className='gap-2 items-center'>
                        <p className='text-skin-accent font-medium'>resolution steps</p>
                        <ol className='list-decimal list-inside'>
                        {openedTicket.resolution_steps.map((step, i) => (
                            <li key={i} className=''>
                                {step}
                            </li>
                        ))}
                        </ol>
                    </div>
                </div>
                </>}
            </div>
            }
            </>
            }
        </div>
    )
}

function InfoMessage({response}) {
return (
    <div className='px-2 flex justify-around gap-3 my-1 pt-1'>
        <p className='text-[10px] text-skin-widget-text font-medium text-center' dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(response)}}/>
    </div>
)
}

function LoadingSection({response}) {
    return (
        <div className='px-8 flex items-center justify-start gap-3'>
            <MdDoneAll className='text-green-600 w-4 h-4'/> <p className='text-[8px] text-skin-widget-text font-light text-center' dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(response)}}/>
        </div>
    )
}

function DocumentViewer({response}){
    return (
        <motion.div initial="hidden" animate="visible" variants={animation}>
                <div className='p-4 text-left border border-skin-backdrop mb-3 bg-skin-backdrop bg-opacity-80 rounded-lg text-skin-widget-text'>
                    <div className='flex gap-2 items-center justify-between'>
                        <a href={response.url}  className='text-skin-accent font-medium text-lg' target={"_blank"} rel="noopener noreferrer">{response.bot_name}</a>
                    </div>
                    <ul className='list-inside list-disc mt-1'>
                        <li className='text-[11px] pb-1 font-medium opacity-80'><b className='underline'>Bot Summary</b>: {response.bot_summary}</li>
                        <li title={response.how_to_use} className='text-[11px] pb-1 font-medium opacity-80 max-h-40 truncate'><b>How To Use</b>: {response.how_to_use}</li>
                        <li title={response.how_to_extend_and_customize} className='text-[11px] pb-1 font-medium opacity-80 max-h-40 truncate'><b>How to extend and customize</b>: {response.how_to_extend_and_customize}</li>
                        <li title={response.url} className='text-[11px] pb-1 font-medium max-h-40 truncate'><b className='opacity-80'>Source Code</b>: <a href={response.url} className="text-blue-500 opacity-90" target="_blank">{response.url}</a></li>
                    </ul>
                </div>
        </motion.div>
    )
        
}

function WelcomeMessage({message, agentInfo}) {
    return (
        <div className='text-skin-widget-text px-2 mb-8 flex flex-col gap-6'>
            <div className='shrink-0 flex flex-col justify-center gap-2 items-center'>{/* <Avatar className='!w-6 !text-[12px] !h-6' alt={agentInfo.agent_name} sx={{ bgcolor: blue[600] }} src={agentInfo.avatar ? agentInfo.avatar:"data.jpg"} />  */}
                <svg className='h-32 w-32' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="support"><path fill="#004463" d="M21 28h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c3 0 5.5-2.5 5.5-5.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 3.6-2.9 6.5-6.5 6.5z"></path><path fill="#607d95" d="M19.5 27.5c0 .6-.4 1-1 1h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1z"></path><path fill="#004463" d="M26 16c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5s.5.2.5.5v3c0 .3-.2.5-.5.5zM5 16c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5s.5.2.5.5v3c0 .3-.2.5-.5.5z"></path><path fill="#607d95" d="M25 15.5h2c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1h-2v-6z"></path><path fill="#01a6ff" d="M25.9 13c.6 0 1.1-.5 1-1.1-.5-5.8-5.4-10.4-11.4-10.4S4.6 6.1 4.1 11.9c-.1.6.4 1.1 1 1.1.5 0 .9-.4 1-.9.4-4.8 4.5-8.6 9.5-8.6s9 3.8 9.5 8.6c-.1.5.3.9.8.9z"></path><path fill="#006ef4" d="M6.1 11.9C6.6 6.4 11 2 16.5 1.5h-1c-6 0-10.9 4.6-11.4 10.4-.1.6.4 1.1 1 1.1.5 0 .9-.4 1-.9-.1 0 0-.1 0-.2 0 0-.1 0 0 0zm11.4-8.4c-.3 0-.7 0-1 .1 4.5.5 8 4.1 8.5 8.6 0 .5.5.9 1 .9.6 0 1-.5 1-1-.6-4.9-4.6-8.6-9.5-8.6z"></path><path fill="#eef2fa" d="M19 18.5h-7c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h1v2l3-2h3c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1z"></path><path fill="#d5e5f2" d="M15 25.2v-.7h-1c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1h-2c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h1v2l2-1.3z"></path><path fill="#4b6c85" d="M7 22.5c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6c0 .6-.4 1-1 1zm17-8c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1z"></path><path fill="#40657c" d="M7 20.5c-.6 0-1-.4-1-1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0 .6-.4 1-1 1zm17 0c-.6 0-1-.4-1-1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0 .6-.4 1-1 1z"></path><path fill="#607d95" d="M6 21.5H4c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1h2v6z"></path><path fill="#4b6c85" d="M4.5 15.5V19c0 .6-.4 1-1 1H3v.5c0 .6.4 1 1 1h2v-6H4.5zm22 0V19c0 .6.4 1 1 1h.5v.5c0 .6-.4 1-1 1h-2v-6h1.5z"></path><path fill="#99b5ce" d="M18.1 21h-5.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.2c.3 0 .5.2.5.5s-.2.5-.5.5zm-2 2h-3.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.2c.3 0 .5.2.5.5s-.2.5-.5.5z"></path></svg>
                <p className='font-light leading-6 text-center max-w-[60%] p-4 py-3 text-skin-secondary text-[14px]'>
                    Hello there! 👋🏻
                    <br/><span className='text-skin-accent font-medium'>I am SynBots Knowledge Genie</span>
                    <br/>An AI-driven conversation system designed to assist you with any <span className='text-skin-accent font-medium'>L1/L2 issues</span>. 
                </p>
            </div>
            <div className='shrink-0 text-center text-sm italic flex flex-col items-center font-light justify-center gap-2'>
                <span>Simply provide a brief description of your problem, and I will help you solve your issues quickly and efficiently.</span>
                <span className='mt-3 text-[10px]'>Here are a few examples of what I can help you with.</span>
                <div className='flex gap-8 items-center'>
                    {message.suggestions.map(({q,a}, index) => (
                        <div onClick={() => message.onSuggestionClick(q,a)}key={index} className='active:scale-95 border-dashed overflow-clip font-normal text-xs shadow-skin-backdrop shadow-opacity-70 hover:border-skin-secondary hover:shadow-md transform duration-150 cursor-pointer ease-in-out rounded-md border-[1.5px] border-skin-backdrop p-5 not-italic text-center flex items-center'>
                        {q}
                        </div>
                    ))}
                    {/* <div className='rounded-md border h-32 w-40'></div> */}
                </div>
            </div>
        </div>
    )
}

function DocumentCard({type, content}) {
    if (type==="standard") {
        return (
            <DocumentViewer response={content}/>
        )
    }
    else if (type==="infoMessage") {
        return (
            <InfoMessage response={content}/>
      )
    }

}
function ChatBubble({resp_id, type, content, agentInfo, setThoughtsOpen}) {
    if (type==="welcomeMsg") {
      return (
          <WelcomeMessage message={content} agentInfo={agentInfo}/>
        )
    }
    else if (type==="userQuery") {
        return (
            <UserResponse query={content}/>
        )
    }
    else if (type==="textResponse") {
        return (
            <BotResponse response={content} agentInfo={agentInfo} setThoughtsOpen={setThoughtsOpen}/>
        )
    }
    else if (type==="infoMessage") {
        return (
            <InfoMessage response={content}/>
      )
    }
    else if (type==="loadingSection") {
        return (
            <LoadingSection response={content}/>
      )
    }
    else if (type==="resultSource") {
        return (
            <SourceCard response={content}/>
      )
    }
    else if (type==="supportTickets") {
        return (
            <TicketsCard response={content}/>
      )
    }
    else if (type==="draftedResponse") {
        return (
            <Drafts resp_id={resp_id} drafts={content} agentInfo={agentInfo} setThoughtsOpen={setThoughtsOpen}/>
      )
    }
    else if (type==="streamResponse") {
        return (
            <StreamResponse response={content} agentInfo={agentInfo} setThoughtsOpen={setThoughtsOpen}/>
        )
    }
}

function ChatSection({chatStack, agentData, messagesEndRef, setThoughtPanelOpen}) {

  return (
    <div id="chatBody" className='flex-grow overflow-y-scroll my-3 px-32 mt-10'>
    {chatStack.map(({id, type, content}) => (
        <ChatBubble key={id} resp_id={id} type={type} content={content} agentInfo={agentData} setThoughtsOpen={setThoughtPanelOpen}/>
    ))}
    <div ref={messagesEndRef} />
    </div>
  )
}

export default ChatSection