import React, { useEffect, useState } from 'react'
import { BsStopCircle } from 'react-icons/bs'
import {GoThumbsup, GoThumbsdown} from "react-icons/go"
import { IoIosRefresh } from 'react-icons/io'
import { IoReloadSharp } from 'react-icons/io5'
import {TbRefreshDot} from "react-icons/tb"

function GenerativeTextBox({ai_response, regenerateEvent, isChatStreamOpen, stopGeneration}) {
  return (
        <div className='flex flex-col gap-2'>
          <div className='flex gap-3 items-center justify-end mt-1 px-1'>
          <p className='text-[8px] text-orange-500'>Disclaimer: Our AI model is constantly learning and its responses may not always be accurate.</p>
            {
              !isChatStreamOpen 
              ? 
              <IoReloadSharp title='Regenerate' onClick={regenerateEvent} className='shrink-0 cursor-pointer hover:text-skin-accent active:scale-75 duration-100 h-3.5 w-3.5'/>
              :
              <BsStopCircle title='Stop generation' onClick={() => stopGeneration()} className='shrink-0 cursor-pointer text-red-500 active:scale-75 duration-100 h-3.5 w-3.5'/>
            }
            <GoThumbsup className='shrink-0 hover:scale-125 active:text-black transform duration-150 cursor-pointer active:scale-95 text-green-600 h-3.5 w-3.5'/>
            <GoThumbsdown className='shrink-0 hover:scale-125 active:text-black transform duration-150 cursor-pointer active:scale-95 text-red-600 h-3.5 w-3.5'/>
          </div>
          
          <p className='text-xs leading-6 border-skin-secondary border-opacity-60 border p-4 rounded-md rounded-l-xl max-h-[450px] overflow-y-scroll' dangerouslySetInnerHTML={{ __html: ai_response.replace('\n', "<br/><br/>")}}/>
        </div>
  )
}

export default GenerativeTextBox