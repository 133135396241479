import React, { useContext, useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {FiEdit3} from "react-icons/fi"
import Editor from "@monaco-editor/react";
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { appContext } from '../../../../App';
import MultiSelectSearch from '../../../../utilities/MultiSelectSearch';
import { CgAddR } from 'react-icons/cg';
import { MdPublishedWithChanges } from 'react-icons/md';
import { Bounce, toast } from 'react-toastify';
import { indexSnippets } from '../../../../utilities/api';
import { CiRead } from 'react-icons/ci';
import { supportedLanguages } from '../../../../utilities/utils';
import { useTranslation } from "react-i18next";

function SnippetEditor({setRecents, snippetData, editType, cancelFormEvent, isReadOnly}) {
    const appState = useContext(appContext).state
    const [activeEditorValue, setEditorValue] = useState(snippetData.code)
    const [name, setName] = useState(snippetData.prompt)
    const [tags, setTags] = useState(snippetData.tags)
    const [lang, setLang] = useState(snippetData.lang)
    const [desc, setDesc] = useState(snippetData.description)
    const [t] = useTranslation("common");

    const saveFunction = (e) => {
        e.preventDefault();
        if (tags.length>0 && activeEditorValue.length>0) {
            const reqBody = {
            prompt: name,
            code: activeEditorValue,
            lang: lang,
            tags: tags,
            description: desc,
            id: snippetData.id,
            }
            const publishPromise = new Promise((resolve) => fetch(indexSnippets, {
                method:"POST", 
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
                },
                body: JSON.stringify(reqBody)
            }).then((response) => {
                if (response.ok) { 
                    return response.json();
            }
            })
            .then((json) => {
                if (json.status === 'success') {
                    setRecents(reqBody)
                    resolve(json)    
                }
                else {

                }
            })
            .catch((error) => {
                console.log(error);
            })
            );
            toast.promise(publishPromise, {
                pending: 'Making a publish request',
                success: "Published!",
                error: 'Failed!'
            }, { transition: Bounce, hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER })
        }
        else {
            // console.log(tags.le)
            if (tags.length===0) {
                toast.error(`There should be atleast one tag!`, {theme: "dark", className:"", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
            }
            if (activeEditorValue.length===0) {
                toast.error(`Please enter the snippet code.`, {theme: "dark", className:"", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
            }
        }
    }

    const deleteFucntion = () => {
    }

    const editorOnchangeHandler = (value) => {
        setEditorValue(value)
    }
  return (
    <div className='w-[70%] flex flex-col rounded-t-md overflow-clip mr-1 border border-b-0 border-skin-secondary border-opacity-30'>

            <form onSubmit={(e) => saveFunction(e)} className={`shrink-0 flex flex-col bg-skin-primary text-skin-secondary px-4`}>
            <fieldset disabled={isReadOnly}>
                {/* <div className='text-xs flex gap-2 items-center'>
                    <FiEdit3/>
                    {snippetData.prompt}
                </div>
                <div className='flex gap-3 items-center'>
                    <IoSaveOutline onClick={() => saveFunction()} className='hover:text-skin-widget-text hover:scale-105 duration-100 rounded-md h-5 w-5 cursor-pointer'/>
                    <IoIosCloseCircleOutline onClick={() => deleteFucntion()} className='hover:text-skin-widget-text hover:scale-105 duration-100 h-5 w-5 cursor-pointer'/>
                    <AiOutlineInfoCircle onClick={() => deleteFucntion()} className='hover:text-skin-widget-text hover:scale-105 duration-100 h-5 w-5 cursor-pointer'/>
                </div> */}
                <div className='flex items-center justify-between h-9 p-1 border-skin-backdrop'>
                    <div className={`${isReadOnly ? "text-red-500": editType==="edit" ? "text-green-500":"text-yellow-500"} text-xs flex font-bold gap-2 items-center`}>
                        {editType==="edit" ? (<FiEdit3 className='h-4 w-4'/>): !isReadOnly? (<CgAddR className='h-4 w-4'/>):(<CiRead className='h-4 w-4'/>)}
                        {editType==="edit" ? (t("AdminConsole.Edit_Snippet")) : !isReadOnly? (t("AdminConsole.Add_Snippet")):(t("AdminConsole.View_Snippet"))}
                        <p className='text-[8px] opacity-60 '>#{snippetData.id}</p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <button type='submit' className='disabled:cursor-not-allowed flex gap-1 group hover:text-green-500 active:scale-95 hover:scale-105 duration-100 items-center text-sm'><MdPublishedWithChanges onClick={() => saveFunction()} className='h-4 w-4'/>{t("AdminConsole.Publish")}</button>
                        <IoIosCloseCircleOutline onClick={() => cancelFormEvent()} className='disabled:cursor-not-allowed hover:text-red-500 hover:scale-105 duration-100 h-5 w-5 cursor-pointer'/>
                        <AiOutlineInfoCircle onClick={() => deleteFucntion()} className='disabled:cursor-not-allowed hover:text-skin-accent hover:scale-105 duration-100 h-5 w-5 cursor-pointer'/>
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-3 p-2'>
                        <div className=''>
                            <p className='text-skin-accent text-[8px]'>{t("AdminConsole.Name")}</p>
                            <input
                            type="text" 
                            required
                            value={name}  
                            onChange={(e) => setName(e.target.value)}
                            className='disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-transparent' 
                            placeholder={`${t(`AdminConsole.Enter_name`)} ...`}/>
                        </div>
                        <div className=''>
                            <p className='text-skin-accent text-[8px]'>{t("AdminConsole.Language")}</p>
                            <select 
                            type="dropdown" 
                            defaultValue={lang} 
                            required
                            onChange={(e) => {setLang(e.target.value);}} 
                            className='disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 h-9 px-3 bg-skin-primary'
                            >
                                <option value="" >{t("AdminConsole.select_Language")}...</option>
                                {supportedLanguages.sort((a, b) => a.label.localeCompare(b.label)).map((s_lang) => (
                                    <option key={s_lang.value} value={s_lang.value}>{s_lang.label}</option>
                                ))}
                            </select>
                        </div>
                        
                        <div className=''>
                            <p className='text-skin-accent text-[8px]'>{t("AdminConsole.Tags")}</p>
                            <MultiSelectSearch value={tags} setValue={setTags}/>
                        </div>
                        <div className='col-span-3'>
                            <p className='text-skin-accent text-[8px]'>{t("AdminConsole.Description")}</p>
                            <textarea
                            required
                            value={desc}  
                            onChange={(e) => setDesc(e.target.value)}
                            className='disabled:cursor-not-allowed outline-none w-full focus:border-skin-accent text-xs border border-skin-backdrop rounded-md p-2 px-3 bg-transparent' 
                            placeholder={`${('AdminConsole.Enter_Description')} ...`}/>
                        </div>
                </div>
                </fieldset>
            </form>
            <div className='relative flex-grow mx-6 mb-2 rounded-lg overflow-clip border border-skin-backdrop'>
                <Editor
                // className='!text-[10px] w-full h-full scrollbar-thin'
                theme={`${appState.theme === "theme-dark-blue" ? 'vs-dark':"light"}`}
                onChange={(event) => editorOnchangeHandler(event)}
                options={{
                    "acceptSuggestionOnCommitCharacter": true,
                    "acceptSuggestionOnEnter": "on",
                    "accessibilitySupport": "auto",
                    "autoIndent": false,
                    "automaticLayout": true,
                    "codeLens": true,
                    "colorDecorators": true,
                    "contextmenu": true,
                    "cursorBlinking": "blink",
                    "cursorSmoothCaretAnimation": false,
                    "cursorStyle": "line",
                    "disableLayerHinting": false,
                    "disableMonospaceOptimizations": false,
                    "dragAndDrop": false,
                    "fixedOverflowWidgets": false,
                    "folding": true,
                    "foldingStrategy": "auto",
                    "fontLigatures": false,
                    "formatOnPaste": false,
                    "formatOnType": false,
                    "hideCursorInOverviewRuler": false,
                    "highlightActiveIndentGuide": true,
                    "links": true,
                    "mouseWheelZoom": false,
                    "multiCursorMergeOverlapping": true,
                    "multiCursorModifier": "alt",
                    "overviewRulerBorder": true,
                    "overviewRulerLanes": 2,
                    "quickSuggestions": true,
                    "quickSuggestionsDelay": 100,
                    "readOnly": isReadOnly,
                    "renderControlCharacters": false,
                    "renderFinalNewline": true,
                    "renderIndentGuides": true,
                    "renderLineHighlight": "all",
                    "renderWhitespace": "none",
                    "revealHorizontalRightPadding": 30,
                    "roundedSelection": true,
                    "rulers": [],
                    "scrollBeyondLastColumn": 5,
                    "scrollBeyondLastLine": false,
                    "selectOnLineNumbers": true,
                    "selectionClipboard": true,
                    "selectionHighlight": true,
                    "showFoldingControls": "mouseover",
                    "smoothScrolling": true,
                    "suggestOnTriggerCharacters": true,
                    "wordBasedSuggestions": true,
                    "wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
                    "wordWrap": "off",
                    "wordWrapBreakAfterCharacters": "\t})]?|&,;",
                    "wordWrapBreakBeforeCharacters": "{([+",
                    "wordWrapBreakObtrusiveCharacters": ".",
                    "wordWrapColumn": 80,
                    "wordWrapMinified": true,
                    "wrappingIndent": "none"
                }}
                defaultLanguage={lang.toLowerCase()}
                language={lang.toLowerCase()}
                value={activeEditorValue}
                />
            </div>
        </div>
  )
}

export default SnippetEditor