import React from 'react'
import TicketCard from '../cards/TicketCard';
import { Divider } from '@mui/material';

function SupportTicketsTab({scratch_pad_data}) {
  console.log(scratch_pad_data)
    const thoughts = scratch_pad_data.map(({id, scratch_pad}) => (
      <div key={id} className='p-4 flex flex-col gap-4 divide-y'>
        <Divider spacing={1}><p className='text-[10px]'>{scratch_pad.thoughts.query}</p></Divider>
        {scratch_pad.related_tickets.map((data, i) => (
          <TicketCard
            key={i}
            data={data}
          />
        ))}
      </div>
    ));
  
    return (
      <div>{thoughts}</div>
    );
}

export default SupportTicketsTab