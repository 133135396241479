import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material'
import {BsArrowsAngleExpand, BsChevronExpand, BsCode} from "react-icons/bs"
import { AiOutlineExpandAlt } from 'react-icons/ai';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

function AtomResultCard({result}) {
    const [isExpand, setExpand] = useState(false);
    const [t] = useTranslation("common");
  return (
    <div className='w-full border border-skin-backdrop rounded-xl py-3 px-4 transform duration-150 ease-in-out'>
        <div className='flex gap-4 justify-between items-center relative overflow-scroll pb-4 border-b border-skin-backdrop scrollbar-hide'>
            <div className='w-[40%] text-sm font-semibold leading-tight text-skin-accent truncate'> 
                {result.bot_name}
                <span className='text-[8px] text-skin-secondary ml-1  truncate'>#{result.bot_id}</span>
                <span className=' flex gap-2 items-end'>
                    <p className='text-[9px] text-skin-secondary font-medium underline underline-offset-2'>{t("Search.created_by")} {result.author}</p>
                    <p className='text-[9px] text-green-600 font-semibold'>{result.status ? "ACTIVE":"INACTIVE"}</p>
                </span>
            </div>
            <div className='w-[15%] leading-none truncate'>
                <p className='text-[8px]'>Category</p>
                <p className='text-xs font-semibold'>{result.category}</p>
            </div>
            <div className='w-[15%] leading-none truncate'>
                <p className='text-[8px]'>Sub Category</p>
                <p className='text-xs font-semibold'>{result.sub_category}</p>
            </div>
            <div title={Math.round(result.score*100).toString()+"%"} className={`w-[10%] leading-none truncate `}>
                <p className='text-[8px] mb-1 text-skin-secondary'>Relevance</p>
                <div className={`grid grid-cols-1 gap-0.5`}>
                    <div className={`${result.score<0.8 ? result.score<0.5 ? "text-red-600":"text-yellow-600":"text-green-600"}`}>
                        <LinearProgress variant="determinate" className='rounded-full' value={result.score*100} color="inherit"/>
                    </div>
                    {/* <div className={`${0.9<0.8 ? 0.9<0.5 ? "text-red-600":"text-yellow-600":"text-green-600"}`}>
                        <LinearProgress variant="determinate" className='rounded-full' value={0.9*100} color="inherit"/>
                    </div> */}
                </div>
            </div>
            <div className='w-[20%] flex justify-end items-center ml-auto gap-2'>
                <a 
                className='gap-2 border-skin-accent hover:bg-skin-backdrop transform duration-150 ease-in-out cursor-pointer p-1 px-2 rounded-full border text-skin-accent shadow-sm flex items-center'
                title='Source Code' 
                href={result.url} 
                target="_blank">
                    <BsCode className=''/>
                    <p className='text-xs transform duration-100 ease-in-out'>{t("AtomSearch.Source_Code")}</p>
                </a>
                {!isExpand ? 
                <MdExpandMore onClick={() => setExpand(!isExpand)} className='pl-1 h-6 w-6 cursor-pointer hover:scale-110 duration-100 transform active:scale-95 hover:text-skin-accent'/>
                :
                <MdExpandLess onClick={() => setExpand(!isExpand)} className='pl-1 h-6 w-6 cursor-pointer hover:scale-110 duration-100 transform active:scale-95 hover:text-skin-accent'/>
                }
            </div>
        </div>
        <div className='flex flex-col justify-between relative overflow-scroll pt-3 opacity-80 scrollbar-hide'>
            <p className={`text-xs mb-1 ${isExpand? "":"truncate"} text-[10px]`} title={result.bot_summary}>
                <span className='font-semibold'>Bot Summary : </span> 
                <span dangerouslySetInnerHTML={{__html:result.bot_summary.replaceAll("<b>", "<b><u>").replaceAll("</b>", "</u></b>")}}/>
            </p>
            <p className={`text-xs mb-1 ${isExpand? "":"truncate"} text-[10px]`} title={result.how_to_use}>
                <span className='font-semibold'>How to use : </span> 
                {result.how_to_use}
            </p>
            <p className={`text-xs ${isExpand? "":"truncate"} text-[10px]`} title={result.how_to_extend_and_customize}>
                <span className='font-semibold'>How to extend and customize : </span> 
                {result.how_to_extend_and_customize}
            </p>
        </div>
    </div>
  )
}

export default AtomResultCard