import React, {useState, useContext} from 'react'
import logo_s from "../assets/logo_icon.png"

import ThemeMenu from '../../utilities/ThemeMenu'
import { VscSettings } from 'react-icons/vsc'
import ReactFlagsSelect from "react-flags-select";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {USER_LANGUAGE_ID_SESSION_ATTRIBUTE_NAME } from "../../utilities/api";
import { appContext} from '../../App'
import ThemeButton from '../../utilities/animated_buttons/ThemeButton'
import DropMenu from './DropMenu'
import OpenAITokenEditor from '../../utilities/OpenAITokenEditor';

function HeaderSection({appName, selectedTab, tabSwitchEvent, tabs, adminClick}) {
    const appDispatcher = useContext(appContext).dispatcher;
    const [isopenAiTokenOpen, setIsopenAiTokenOpen] = useState(false)
    const [selectedLang, setSelectedLang] = useState(i18next.language.toUpperCase())
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    const [t] = useTranslation("common");

    const handleSelectedLang = (languageCode) => {
        setSelectedLang(languageCode)
        sessionStorage.setItem(
            USER_LANGUAGE_ID_SESSION_ATTRIBUTE_NAME,
            languageCode.toLowerCase()
        );
        i18next.changeLanguage(languageCode && languageCode.toLowerCase());
        appDispatcher({type:"setLanguage", value:languageCode.toLowerCase()})
    }


    return (
        <div className='h-[10%] flex py-4 justify-between relative'>
            {isopenAiTokenOpen && <OpenAITokenEditor modalIsOpen={isopenAiTokenOpen} closeEvent={() => setIsopenAiTokenOpen(false)}/>}
            <div className='flex items-center gap-x-1 px-3 cursor-pointer' onClick={() => window.location.reload(false)}>
                <img
                    src={logo_s}
                    alt="logo"
                    className={`cursor-pointer duration-600 h-7 ml-1`}
                />
                <div className={`text-[10px] flex-grow duration-200 hidden lg:flex`}>
                    <h1 className={`text-skin-accent`}>{appName[0]}</h1>
                    <h1 className='text-skin-accent font-bold whitespace-nowrap'>{appName[1]}</h1>
                </div>
            </div>
            <div className='flex-grow flex justify-end ml-20'>
                <div className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex items-center gap-x-12 leading-none justify-center'>
                    {tabs.map((tab) => (
                        <div key={tab.tabID} className={`${selectedTab.tabID === tab.tabID ? "text-skin-accent border-skin-accent":"text-gray-400 border-transparent"} cursor-pointer border-b-2 py-1.5`} onClick={() => tab.tabID !== 'E2ETesting' ? tabSwitchEvent(tab): ''}>{tab.tabID === 'E2ETesting' ? (<a href="https://digital-assurance-luna-ai-salesforce-7nmzn2wtma-uc.a.run.app/login" target="_blank" rel="noopener noreferrer">{tab.tabName[0]}<p className='text-[10px] font-semibold align-sub'>{tab.tabName[1]}</p></a>) : (<>{t(`Search.${tab.tabName[0]}`)}<p className='text-[10px] font-semibold align-sub'>{tab.tabName[1]}</p></>)}</div>
                    ))}
                </div>
                <div className='flex items-center justify-end gap-3 mr-4'>
                    {/* <div className='rounded-xl text-[10px] font-medium p-1 border flex gap-2 items-center'><CiUser className='h-5 w-5'/></div> */}
                    <div className='hidden gap-1 items-center lg:flex'>
                        <p className='text-[10px]'>{t("Search.Powered_by")}</p>
                        <p className='text-[12px] text-skin-accent font-bold'>SyntBots</p>
                    </div>
                    <ThemeButton/>
                    {(appName[0]==="Developer" && ["admin", "superadmin"].includes(userDetails.user_role) ) && 
                    <div onClick={adminClick} className='cursor-pointer hover:bg-skin-backdrop active:border-green-600 duration-100 flex gap-1 items-center border px-2 py-1 border-skin-secondary border-opacity-60 rounded-lg text-green-600 opacity-90'>
                        <VscSettings className='h-5 w-5'/>
                        <p className='hidden lg:block text-xs'>{t("Search.console")}</p>
                    </div>
                    }
                    {/* <ReactFlagsSelect
                        countries={["US", "FR", "DE"]}
                        customLabels={{
                            US: "Eng",
                            FR: "Fre",
                            DE: "Ger",
                        }}
                        placeholder="Eng"
                        showOptionLabel={true}
                        selected={selectedLang}
                        onSelect={(code) => handleSelectedLang(code)}
                        className="menu-flags text-[16px] rounded-lg items-center"
                    /> */}
                    <DropMenu openOpenAiEditorAction={() => setIsopenAiTokenOpen(true)}/>
                </div>
            </div>
        </div>
    )
}

export default HeaderSection