import React, {useState } from 'react'
import {  motion } from "framer-motion"
import {CgClose} from "react-icons/cg"
import BackDrop from '../../../utilities/BackDrop'
import {RiAdminLine} from "react-icons/ri"
import { RxUpload } from 'react-icons/rx'
import SideBar from './SideBar'
import SnippetManagement from './snippetManager/SnippetManagement'
import CodeReviewer from './CodeReviewer'
import { useTranslation } from "react-i18next";
import UserManagement from './userManager/UserManagement'
import { FaUserCog } from 'react-icons/fa'
import { VscOpenPreview } from 'react-icons/vsc'
import { BsFileEarmarkCode, BsFileEarmarkCodeFill } from 'react-icons/bs'
import { BiGitRepoForked } from 'react-icons/bi'
import RepoManagement from './repoManager/RepoManagement'


const modalAnimation = {
  hidden:{
      y:"100vh",
      opacity:0
  },
  visible:{
      y: "0",
      opacity: 1,
      transition:{
          duration: 0,
          type: "spring",
          damping: 100,
          stiffness: 500,
      }
      
  },
  exit:{
      y:"100vh",
      opacity: 0
  }

}

function AdminConsole({handleClose}) {
  const [t] = useTranslation("common");
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [toggleState, setToggleStage] = useState(0)
  const toggleTab = (tabIndex) =>{
    setToggleStage(tabIndex)
  }
  const menu = [
    {
      menu_id:0,
      title: "Upload Snippets",
      key: "Upload Snippets",
      Icon: BsFileEarmarkCode,
      access: ["admin", "superadmin"]
    },
    {
      menu_id:1,
      title: "Upload Repositories",
      key: "Upload Repositories",
      Icon: BiGitRepoForked,
      access: ["admin", "superadmin"]
    },
    {
      menu_id:2,
      title: "User Management",
      key: "User Management",
      Icon: FaUserCog,
      access: ["superadmin"]
    }
  ];

  return (
    <BackDrop>

      <motion.div onClick={(e) => e.stopPropagation()}
      className="absolute bottom-0 h-[98%] rounded-t-2xl w-screen bg-skin-primary flex flex-col overflow-y-scroll scrollbar-hide"
      variants={modalAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
      >
        <header className = 'h-[6%] bg-skin-primary px-4 items-center flex justify-between'>
          <div className='flex flex-col items-end'>
              <div className='w-fit px-3 flex gap-3 items-center text-skin-widget-text'>
                <RiAdminLine className='w-5 h-5'/>
                <p className='text-[16px] font-semibold'>{t('AdminConsole.Admin_Console')}</p>
              </div>
          </div>
          <CgClose onClick={handleClose}
            className = {`mr-2 mt-2 h-6 w-6 text-skin-widget-text hover:text-red-500 hover:scale-110 transition duration-150 cursor-pointer`}
          />
        </header>
        <div className='flex-grow flex h-[94%] relative'>
          <SideBar menu={menu} toggleTab={toggleTab} setMenuOpen={setMenuOpen} isMenuOpen={isMenuOpen} toggleState={toggleState}/>
          {toggleState===0 && <SnippetManagement/>}
          {toggleState===1 && <RepoManagement/>}
          {toggleState===2 && <UserManagement/>}
        </div>
      </motion.div>
    </BackDrop>
  )
}

export default AdminConsole