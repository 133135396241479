import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { deleteRepoAPI } from '../../../../utilities/api';
import { Bounce, toast } from 'react-toastify';
import DeleteConfirmation from '../../../../utilities/DeleteConfirmation';
import { RiEdit2Line } from 'react-icons/ri';
import { MdOutlineDelete } from 'react-icons/md';

function RepoCard({repoDetails, editEvent, deleteEvent, viewRepoEvent}) {
  const [isDeleteOpen, setDeleteOpen] = useState(false)

  const [t] = useTranslation("common");
  const deleteSnippet = (id) => {
      const reqBody = {
          id: id,
      }
      setDeleteOpen(false)
      const publishPromise = new Promise((resolve) => fetch(deleteRepoAPI, {
          method:"POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
            },
          body: JSON.stringify(reqBody)
      }
      ).then((response) => {
          if (response.ok) { 
              return response.json();
      }
      })
      .then((json) => {
          if (json.status === 'success') {
            resolve(json)    
            deleteEvent(repoDetails)
          }
      })
      .catch((error) => {
          console.log(error);
      })
      );
      toast.promise(publishPromise, {
          pending: `Deleting Snippet #${id}`,
          success: "Deleted",
          error: 'Failed!'
        }, { transition: Bounce, hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER })
  }
  return (
    <div className={`${repoDetails.bot_id===1 & "border-skin-secondary"} h-12 border shrink-0 rounded-xl border-skin-backdrop hover:border-skin-secondary duration-100 flex gap-1 justify-between p-2 px-4 items-center`}>
        {isDeleteOpen && <DeleteConfirmation modalIsOpen={isDeleteOpen} submitEvent={() => deleteSnippet(repoDetails.bot_id)} closeEvent={() => setDeleteOpen(false)} confirmation={"do you wanna delete?"} elId={repoDetails.bot_id}/>}
        <div className='w-[260px] truncate cursor-pointer' title={repoDetails.bot_name} onClick={() => viewRepoEvent(repoDetails)}>
            <div className='text-[8px] flex justify-between pr-3 text-skin-accent'>
                {"repository name"}
                <p className='text-[8px] underline underline-offset-1'>{"author"}: {repoDetails.author}</p>
            </div>
            <p className='text-sm truncate'>{repoDetails.bot_name}</p>
        </div>
        <div className='flex gap-3'>
            <RiEdit2Line onClick={()=> editEvent(repoDetails)} className='h-4 w-4 cursor-pointer hover:text-green-500'/>
            <MdOutlineDelete onClick={()=> setDeleteOpen(true)} className='h-4 w-4 cursor-pointer hover:text-red-500'/>
        </div>
    </div>
  )
}

export default RepoCard