import React from 'react'
import { useTranslation } from 'react-i18next';
import {CiPaperplane, CiSearch} from "react-icons/ci"
import {IoIosClose} from "react-icons/io"
import { VscLoading } from 'react-icons/vsc';
import TextareaAutosize from 'react-textarea-autosize';

function SearchArea({query, queryOnChange, onSearch, onKeyDownEvent, autoCompleteLoading}) {
    const [t] = useTranslation("common");
  return (
    <div className='relative flex flex-grow border border-skin-secondary rounded-xl px-12 p-2 items-center bg-skin-primary focus-within:border-skin-accent'>
        <CiSearch 
        className=' absolute left-2 top-1.5 hover:bg-opacity-75 w-8 h-8 rounded-xl text-skin-accent'
        />
        <TextareaAutosize
        type="text" 
        value={query}  
        onChange = {(event) => queryOnChange(event.target.value)}
        onKeyDown={event => onKeyDownEvent(event, query)}  
        autoFocus
        className='max-h-[120px] outline-none p-1 px-1 flex-grow text-sm bg-transparent scrollbar-hide resize-none' 
        placeholder={`${t(`AtomSearch.Type_your_query_here`)}...`}
        />
        {/* <VscLoading 
        className={`h-4 w-4 animate-spin ${autoCompleteLoading ? "text-skin-accent":"text-transparent"}`}
        />
        <IoIosClose 
        onClick={() => queryOnChange("")}
        className={`${query.length!==0 ? "":"text-transparent disabled hidden"} cursor-pointer text-skin-secondary hover:text-skin-accent w-7 p-0.5 h-7 transition duration-100 border-r border-skin-secondary`}
        /> */}
        <CiPaperplane 
        onClick={() => onSearch(query)}
        className={`absolute right-2 top-2 cursor-pointer hover:scale-110 text-skin-accent active:scale-95 w-8 h-7 transition duration-100 ml-2`}
        />
    </div>
  )
}

export default SearchArea