import React from 'react';
import AccordianCard from '../cards/AccordianCard';
import { Divider } from '@mui/material';

function ThoughtsTab({ scratch_pad_data, draft_id}) {
  const thoughts = scratch_pad_data.map(({id, scratch_pad}) => (
    <div className='p-4 flex flex-col gap-4 divide-y'>
      <Divider spacing={1}><p className='text-[10px]'>{scratch_pad[draft_id].thoughts.query}</p></Divider>
      {scratch_pad[draft_id].thoughts.thoughts.map((data, i) => (
        <AccordianCard
          key={i}
          data={data}
        />
      ))}
    </div>
  ));

  return (
    <div>{thoughts}</div>
  );
}

export default ThoughtsTab;
