import { FC, memo, useContext, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark, prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { programmingLanguages, generateRandomString } from '../utils/CodeBlock';
import { HiOutlineClipboardDocument} from 'react-icons/hi2';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2'
import { BsDownload } from 'react-icons/bs';
import { appContext } from '../../../../App';



function CodeBlock({ language, value }) {
  const [isCopied, setIsCopied] = useState(false);
  const appState = useContext(appContext).state

  const copyToClipboard = () => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return;
    }

    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };
  const downloadAsFile = () => {
    const fileExtension = programmingLanguages[language] || '.file';
    const suggestedFileName = `file-${generateRandomString(
      3,
      true,
    )}${fileExtension}`;
    const fileName = window.prompt(
      'Enter file name' || '',
      suggestedFileName,
    );

    if (!fileName) {
      // user pressed cancel on prompt
      return;
    }

    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <div className="bg-skin-backdrop rounded-lg overflow-clip shadow-inner">
      <div className="flex items-center font-semibold justify-between py-1.5 px-4">
        <span className="lowercase text-skin-accent">{language}</span>

        <div className="flex items-center gap-2">
          <button
            className="flex gap-1.5 items-center rounded bg-none p-1 text-skin-accent"
            onClick={copyToClipboard}
          >
            {isCopied ? <HiOutlineClipboardDocumentCheck size={18}/> : <HiOutlineClipboardDocument size={18}/>}
            {isCopied ? 'Copied!' : 'Copy code'}
          </button>
          <button
            className="flex items-center rounded bg-none p-1 text-skin-accent"
            onClick={downloadAsFile}
          >
            <BsDownload size={16} />
          </button>
        </div>
      </div>

      <SyntaxHighlighter
        language={language}
        style={appState.theme === "theme-dark-blue" ? atomDark:prism}
        customStyle={{ margin: 0 , borderRadius: '0 0 0 0'}}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
};
CodeBlock.displayName = 'CodeBlock';

export default memo(CodeBlock);