import { useContext } from 'react';
import { Outlet, Navigate, Link, useLocation } from 'react-router-dom';
import { appContext } from '../App';
import { AiOutlineCode } from 'react-icons/ai'
import { HiOutlineSwitchHorizontal } from 'react-icons/hi'
import { BiSupport } from 'react-icons/bi';
import LoadingScreen from '../utilities/LoadingScreen';

const PrivateRoutes = ({isLoading, stateSave}) => {
    const isAuth = JSON.parse(sessionStorage.getItem("userDetails"));
    const appState = useContext(appContext).state
    const location = useLocation()
    const currentpath = location.pathname.split("/")[1]
    return(
        isAuth && isAuth.isActive ? (
            <>
                {(appState.isLoading) && <LoadingScreen div_position="items-center absolute top-[80%] left-1/2 -translate-x-1/2"/> }
                {["superadmin"].includes(isAuth.user_role) && <Link to={currentpath!=="developer"? "/developer":"/support"} key="1"  title={currentpath!=="developer" ? "DeveloperAssistant":"supportAssistant"}>
                    <div className={`${appState.theme} hover:bg-skin-widget-text hover:text-skin-primary duration-100 ease-in-out active:scale-95 z-30 absolute bottom-5 right-5 text-skin-widget-text border border-skin-backdrop cursor-pointer flex gap-4 bg-skin-primary rounded-full p-2 px-3 shadow-md shadow-skin-backdrop`}>
                        <HiOutlineSwitchHorizontal className="h-5 w-5"/>
                        {currentpath!=="developer"? <AiOutlineCode className="h-5 w-5"/> : <BiSupport className="h-5 w-5"/>}
                    </div>
                </Link>}
                <div className='flex flex-col flex-grow'>
                    <Outlet basename='/'/>
                </div>
            </>
        ) : <Navigate to="/"/>
    )
}

export default PrivateRoutes