import React, { useContext, useEffect, useRef, useState } from 'react'
import { BsSendFill } from 'react-icons/bs'
import { IoChatbubblesOutline } from 'react-icons/io5'
import { appContext } from '../App'
import HeaderSection from '../components/header/HeaderSection'
import ChatSection from '../components/support_assistant/ChatSection'
import { getRandID } from '../utilities/utils'
import * as DOMPurify from 'dompurify';
import axios from 'axios';
import './SupportAssistant.css';
import LoadingSection from '../components/support_assistant/LoadingSection'
import { help_desk_search, help_desk_search_docs, help_desk_stream } from '../utilities/api'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import ThoughtModal from '../components/support_assistant/thoughts_panel/ThoughtModal'
import { json } from 'react-router-dom'

function SupportAssistant() {
  const appState = useContext(appContext).state
  const userDetails = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : {}
  const [query, setQuery] = useState("")
  const [chatStack, setChatStack] = useState([])
  const [thoughts, setThoughts] = useState([])
  const [isThoughtPanelOpen, setThoughtPanelOpen] = useState(false)
  const [chatHistory, setChatHistory] = useState([])
  // const [agentData, setAgentData] = useState({})
  const [selected_response, setSelectedResponse] = useState({resp_id:"", draft_id:""})
  const [isLoading, setLoading] = useState(false)
  const [isAILoading, setAILoading] = useState(false)
  const messagesEndRef = useRef(null)
  const [currentGenResponse, setCurrentGenResponse] = useState("")
  const suggestions = [
    {q:`How do I activate my PKI card`},
    {q:`Defender Antimalware is not working properly`},
    {q:`Issue with my target time in the Timesheet`}
  ]
  const historyRef = useRef();
  historyRef.current = chatHistory;
  const selectedResponseRef = useRef();
  selectedResponseRef.current = selected_response;
  const chatStackRef = useRef();
  chatStackRef.current = chatStack;
  useEffect(() => {
    setChatStack([])
    setChatStack((stack) => stack.concat([{id:1 , type:"welcomeMsg", content:{suggestions:suggestions, onSuggestionClick:buttonClickEvent}}]))
    setChatHistory([])
    // setChatHistory((stack) => stack.concat([{id:1 , type:"welcomeMsg", content:{suggestions:suggestions, onSuggestionClick:buttonClickEvent}}]))
  }, [])
  
  const buttonClickEvent = (ques,resp) => {
    processUserQuery(ques, ques)
  }

  const thoughtClickEvent = (resp_id, draft_id) => {
    setThoughtPanelOpen(true)
    setSelectedResponse({resp_id:resp_id, draft_id:draft_id})
  }

  const processUserQuery = (label, val) => {
    const is_token_updated = sessionStorage.getItem("openAiToken").length > 0
    if (is_token_updated) {
      if (val!==""){
          setLoading(true)
          setAILoading(true)
          setChatStack((stack) => stack.concat({id:"user"+getRandID() , type:"userQuery", content:{label:label, user_name:userDetails.name, avatar:userDetails.picture}}))
          setChatHistory((stack) => stack.concat({id:"user"+getRandID() , type:"userQuery", content:{label:label, user_name:userDetails.name, avatar:userDetails.picture}}))
          var elem = document.getElementById('chatBody');
          setTimeout(() => {
                  elem.scrollTop = elem.scrollHeight;
                  getChatSreamResponse(val)
              }, 10);
      }
    }
    else {
      toast.error(`Please add an OpenAI token to use this service.`, {theme: "colored", className:"text-[12px]", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
    }
  }

  const getTextFromCard = () => {
    let history = []
    for (let i = 0; i < historyRef.current.length-1; i++) {
      const card = historyRef.current[i];
      // console.log(card)
      if (card.type==="userQuery"){
        history = history.concat({role:"user", content:card.content.label}) 
      }
      else if (card.type==="textResponse"){
        history = history.concat({role:"assistant", content:card.content.chat_response})
      }
      else if (card.type==="draftedResponse"){
        history = history.concat({role:"assistant", content:card.content.chat_response})
      }
  }
    return history
  }

  const getChatResponse = async(query) => {    
    
    let history = getTextFromCard()
    console.log(history)
    const reqdata = JSON.stringify({ 
      email: userDetails.email,
      "query" : DOMPurify.sanitize(query)   ,
      history: history  
      })
      setTimeout(() => {
        pushToChat({id:"loading"+getRandID() , type:"loadingSection", content:"Fetching relevant sources..."})
        setTimeout (() => {
          pushToChat({id:"loading"+getRandID() , type:"loadingSection", content:"Optimizing with AI..."})
        }, 1000)
      }, 0)
      const response = await axios.post(help_desk_search, reqdata, {headers: {
        "Content-Type": "application/json",
        Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
        }})
      if (response.data.status==="success"){
          processResponse(response.data.ai_response)
      }
      else {
        toast.error(`Something went wrong. Please try again. ${response.data.error}`, {theme: "colored", className:"text-[12px]", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
        setAILoading(false)
        setLoading(false)
      }
  }

  const modifyResponse = (id, chat) => {
    const updatedData = chatStackRef.current.map(obj => {
      if (obj.id === id) {
        return { ...obj, content: {...obj['content'], chat:chat}}
      }
      return obj;
    });
    setChatStack(updatedData)
  };
const modifyCitations = (id, cites) => {
    const updatedData = chatStackRef.current.map(obj => {
      if (obj.id === id) {
        return { ...obj, content: {...obj['content'], citations:cites}}
      }
      return obj;
    });
    setChatStack(updatedData)
  };

  const getChatSreamResponse = async(query) => {
      const decoder = new TextDecoder();
      let history = getTextFromCard()
      const stream_id = "stream"+getRandID()
      const requestBody = { 
        email: userDetails.email,
        "query" : DOMPurify.sanitize(query),
        history: history  
        }

      const doc_response = await axios.post(
        help_desk_search_docs, 
        JSON.stringify(requestBody),
        {method: "POST", headers: { "Content-Type": "application/json", Authorization: "bearer "+ sessionStorage.getItem("bearerToken") }}
      )
      setTimeout(() => {
        pushToChat({id:"loading"+getRandID() , type:"loadingSection", content:"Fetching relevant sources..."})
        pushToChat({id:"loading"+getRandID() , type:"loadingSection", content:"Optimizing with AI..."})
        setTimeout (() => {
          if (doc_response.data.status==="success"){
            pushToChat({id:"chat"+getRandID() , type:"resultSource", content:{sources:doc_response.data.ai_response.search_results, renderCompleteAction:renderCompleteAction}})
            pushToChat({id:stream_id , type:"streamResponse", content:{"chat":"", "citations":[]}})
            scrollToBottom(0)
          }
        }, 500)
      }, 0)
      
      const response = await fetch(help_desk_stream, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "bearer "+ sessionStorage.getItem("bearerToken")
        },
        body: JSON.stringify(requestBody)
      })
      const reader = response.body.getReader();
      let complete_response = "";
      reader.read().then(function processText({ done, value }) {
          if (done) {
              console.log("Stream complete");
              setAILoading(false)
              setLoading(false)
              console.log(doc_response.data)
              if (doc_response.data.status==="success"){
                pushToChat({id:"chat"+getRandID() , type:"supportTickets", content:{tickets:doc_response.data.ai_response.scratch_pad.related_tickets, renderCompleteAction:renderCompleteAction}})
                scrollToBottom(0)
              }
              
              return;
          }
          let string = decoder.decode(value);
          try {
            const data = JSON.parse(string)
            if (data['type'] === "citations"){
              console.log(data['content']['data'])
              modifyCitations(stream_id, data['content']['data'])
            }
          }
          catch(err) {
            console.log(string)
            complete_response += string;
            modifyResponse(stream_id, complete_response)
          }
          scrollToBottom(0)
          return reader.read().then(processText);
      });                
    }

  const processResponse = (response) => {
    const response_id = "chat"+getRandID()
    thoughts.push({id:response_id, scratch_pad:response.scratchpad})
    console.log(thoughts)
    setThoughts(thoughts)
    setTimeout(() => {
      if (response.flag === "ai") {
        pushToChat({id:"loading"+getRandID() , type:"loadingSection", content:"Generating response..."})
      }
    }, 500)
    setTimeout(() => {
      setAILoading(false)
      setLoading(false)
      if (response.drafts.length !== 0) {
        pushToChat({id:response_id , type:"draftedResponse", content:response.drafts})
      }
    //   pushToChat({id:response_id , type:"textResponse", content:{chat_response:response.chat_response, citations:response.cited_sources, renderCompleteAction:renderCompleteAction}})//, sources: response.sources.length>0 ? `<br/><br/>Sources: <br/><div style='display: flex; flex-wrap: wrap; gap: 0.5rem; align-items: center;'> ${response.sources}</div>`:"", flag:response.flag, meta:response.search_results}})
    //   if (response.scratch_pad.related_tickets.length !== 0) {
    //     pushToChat({id:"chat"+getRandID() , type:"supportTickets", content:{tickets:response.scratch_pad.related_tickets, renderCompleteAction:renderCompleteAction}})
    //   }
    }, 1000)
  }

  const renderCompleteAction = (action) => {
    scrollToBottom(0)
  }
  
  const scrollToBottom = (timeout) => {
    var elem = document.getElementById('chatBody');
    setTimeout(() => {
          elem.scrollTop = elem.scrollHeight;
        }, timeout);
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

const pushToChat = (val) => {
    if (val.type==="userQuery" || val.type==="infoMessage"){
        setTimeout(() =>{
            setChatStack((stack) => stack.concat(val))
            val.type==="userQuery" && setChatHistory((stack) => stack.concat(val))
            scrollToBottom(0)
        },10)
    }
    else if (val.type==='draftedResponse'){
      console.log(val)
        setTimeout(() =>{
            setChatStack((stack) => stack.concat(val))
            setChatHistory((stack) => stack.concat({id:val.id, type:val.type, content:{chat_response:val.content[1].chat_response}}))
            scrollToBottom(0)
        },10)
    }
    else {
    setTimeout(() =>{
        setChatStack((stack) => stack.concat(val))
        setChatHistory((stack) => stack.concat(val))
        scrollToBottom(0)
            }, 10)
        }
  }

  const sendQuery = () => {
    processUserQuery(query, query)
    setQuery("")
  }
  return (
    <div className={`h-screen w-full px-4 text-skin-secondary ${appState.theme} bg-skin-primary`}>
        {isThoughtPanelOpen && <ThoughtModal handleClose={() => setThoughtPanelOpen(false)} scratch_pad_data={thoughts.filter((val) => val.id===selected_response.resp_id)} draft_id={selected_response.draft_id}/>}
        <HeaderSection appName={["Knowledge", "Genie"]} selectedTab={{}} tabSwitchEvent={() =>  console.log("")} tabs={[]} adminClick={() => console.log(true)}/>
        <div className='relative flex flex-col h-[90%] border-x border-t border-skin-backdrop rounded-t-lg'>
          <ChatSection id="chatBody" chatStack={chatStack} agentData={{}} messagesEndRef={messagesEndRef} setThoughtPanelOpen={thoughtClickEvent}/>
          <div className='bg-skin-primary sticky z-10 w-full mx-auto flex items-center gap-4 p-4 justify-center'>
            <div className='ml-20 relative focus-within:border-skin-accent rounded-full flex-grow border border-skin-backdrop max-w-[60%] h-12 flex gap-3 items-center justify-between px-4'>
              <IoChatbubblesOutline className='h-5 w-5 text-skin-accent'/>
              <input
              type="text" 
              value={query} 
              disabled = {isLoading} 
              onChange = {(event) => setQuery(event.target.value)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  processUserQuery(event.target.value, event.target.value)
                  setQuery("")
                }
              }} 
              autoFocus
              className='outline-none p-3 px-1 flex-grow text-sm bg-transparent' 
              placeholder={`What can i help you with ?`}
              />
              <LoadingSection isLoading={isLoading} chatLoading={isAILoading} isAIsuccess={false} isSearchsuccess={false}/>
              <BsSendFill 
               onClick={() => {!isLoading && sendQuery()}}
               className='h-5 w-5 text-skin-accent cursor-pointer hover:scale-110 active:scale-95 duration-100 transform'/>
            </div>
            {historyRef.current.length>0 && <button disabled = {isLoading} className='disabled:pointer-events-none disabled:opacity-40 text-skin-accent text-[10px] hover:scale-105 active:scale-95 duration-100 transition' onClick={() => setChatHistory([])}>reset context</button>}
          </div>
        </div>
        
        {/* <Link to="/developer" key="1"  title="DeveloperAssistant">
            <div className={`${appState.theme} hover:bg-skin-widget-text hover:text-skin-primary duration-100 ease-in-out active:scale-95 z-30 absolute bottom-5 right-5 text-skin-widget-text border border-skin-backdrop cursor-pointer flex gap-4 bg-skin-primary rounded-full p-2 px-3 shadow-lg shadow-skin-backdrop`}>
                <HiOutlineSwitchHorizontal className="h-5 w-5"/>
                <AiOutlineCode className="h-5 w-5"/>
            </div>
        </Link> */}
      <ToastContainer className="z-umax" autoClose={4000}/>

    </div>
  )
}

export default SupportAssistant