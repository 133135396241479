const base_path = "https://developerassist.eastus.cloudapp.azure.com:443" // for prod
// const base_path = "http://localhost:8000" // for prod

export const USER_LANGUAGE_ID_SESSION_ATTRIBUTE_NAME = 'syntBotsLanguageId'

//atom apis
export const search_atom_search = `${base_path}/api/atom_search`
export const autocomplete_reposearch = `${base_path}/api/autocomplete_atom`
export const search_ai_atom_search = `${base_path}/api/generate_text`
export const generate_chat_stream = `ws://localhost:7002`
export const findRepos = `${base_path}/api/find_repo`
export const recentRepos = `${base_path}/api/get_recent_repos`
export const indexRepos = `${base_path}/api/index_repos`
export const deleteRepoAPI = `${base_path}/api/delete_repo`

//snippets apis
export const get_all_Snippets = `${base_path}/api/get_all_snippets`
export const autocomplete_snippets = `${base_path}/api/autocomplete_snippets`
export const findSnippets = `${base_path}/api/find_snippet`
export const recentSnippets = `${base_path}/api/get_recent_snippets`
export const indexSnippets = `${base_path}/api/index_snippets`
export const deleteSnippetAPI = `${base_path}/api/delete_snippet`
export const search_snippets = `${base_path}/api/snippet_search`

//generative AI apis
export const generate_code_external = `${base_path}/api/generate_code_3_5`
export const generate_code_stream = `${base_path}/api/generate_code`

//helpdesk apis
export const help_desk_search = `${base_path}/api/helpdesk_search`
export const help_desk_search_docs = `${base_path}/api/helpdesk_search_docs`
export const help_desk_stream = `${base_path}/api/helpdesk_search_stream`

//auth apis
export const getBearerTokenAPI = `${base_path}/api/token`
export const getUserDetailsAPI = `${base_path}/api/get_user_details`
export const postOpenAiTokenAPI = `${base_path}/api/update_openai_token`

//user management apis
export const getAllUsersAPI = `${base_path}/api/get_all_users`
export const updateUserAccessAPI = `${base_path}/api/update_user_access`