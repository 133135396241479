import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { googleLogout } from '@react-oauth/google';

export const getRandID = () => {
    return Math.floor(100000 + Math.random() * 999999)
}

export const validateCodeInput = (code) => {
  const javaRegex = /(\b(public|private|protected)\b\s+)?\b(class|interface)\b\s+(\w+)\b\s*(<[\w\s,]+>)?\s*\{[\s\S]*?\}/;
  const pythonRegex = /(def|class|import|from|as|if|elif|else|for|while|try|except|finally|with|as|global|nonlocal|assert|async|await|yield|return|break|continue|raise|pass|lambda)[^\w]/g;
  
  const hasJavaCode = javaRegex.test(code);
  const hasPythonCode = pythonRegex.test(code);
  
  if (hasJavaCode && hasPythonCode) {
    // console.log('Input contains both Java and Python code.');
  } else if (hasJavaCode) {
    // console.log('Input contains Java code.');
  } else if (hasPythonCode) {
    // console.log('Input contains Python code.');
  } else {
    // console.log('Input does not contain Java or Python code.');
  }
  return hasJavaCode || hasPythonCode;
}

export const supportedLanguages = [
    {"label": "TypeScript", "value": "TypeScript"},
    {"label": "JavaScript", "value": "JavaScript"},
    {"label": "Angular", "value": "Angular"},
    {"label": "CSS", "value": "CSS"},
    {"label": "LESS", "value": "LESS"},
    {"label": "SCSS", "value": "SCSS"},
    {"label": "JSON", "value": "JSON"},
    {"label": "HTML", "value": "HTML"},
    {"label": "XML", "value": "XML"},
    {"label": "PHP", "value": "PHP"},
    {"label": "C#", "value": "C#"},
    {"label": "C++", "value": "C++"},
    {"label": "Razor", "value": "Razor"},
    {"label": "Markdown", "value": "Markdown"},
    {"label": "Diff", "value": "Diff"},
    {"label": "Java", "value": "Java"},
    {"label": "VB", "value": "VB"},
    {"label": "CoffeeScript", "value": "CoffeeScript"},
    {"label": "Handlebars", "value": "Handlebars"},
    {"label": "Batch", "value": "Batch"},
    {"label": "Pug", "value": "Pug"},
    {"label": "F#", "value": "F#"},
    {"label": "Lua", "value": "Lua"},
    {"label": "Powershell", "value": "Powershell"},
    {"label": "Python", "value": "Python"},
    {"label": "Ruby", "value": "Ruby"},
    {"label": "SASS", "value": "SASS"},
    {"label": "R", "value": "R"},
    {"label": "Objective-C", "value": "Objective-C"},
    {"label": "ABAP", "value": "ABAP"},
    {"label": "Apex", "value": "Apex"},
    {"label": "Scala", "value": "Scala"},
    {"label": "Swift", "value": "Swift"},
  ]

export function createSearchContext(contextMap, question) {
    const { bot_name, bot_summary, author, how_to_extend_and_customize, how_to_use, used_for, url, api_docs } = contextMap;
    const contextFormat = `
    Context: Name of the bot is ${bot_name}. Summary of ${bot_name} - ${bot_summary}. Author for ${bot_name} is ${author}. customize or extend as follows: ${bot_name} - ${how_to_extend_and_customize}. Usage for ${bot_name} is -  ${how_to_use}. Here is how to use the bot: ${how_to_use}. ${bot_name} is used for the following -  ${used_for}. Git/gitlab/github repository or Source code for ${bot_name} can be found at ${url}. The API data for ${bot_name} can be found at ${api_docs}. ${bot_name} is docker compatible. The license, deployment steps, documentation for ${bot_name} can be found in at ${url}`;
    
    const userQuery = `${contextFormat} Question: ${question} Answer: `;
    // console.log("userQuery", userQuery)
    return userQuery;
  }

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

export const decodeJWT = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  export const logOut = (appDispatcher) => {
    sessionStorage.removeItem("userDetails")
    sessionStorage.removeItem("openAiToken")
    appDispatcher({type:"setUserDetails", value:{}})
    appDispatcher({type:"setOpenAIToken", value:""})
    appDispatcher({type:"setToken", value:""})
    googleLogout();
}