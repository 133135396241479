import React, { useState } from "react";
import { Bounce, toast } from "react-toastify";
import CodeResultCard from "./CodeResultCard";

function SnippetResultsSection({searchQuery,searchResults}) {

  const copyClick = () => {
    toast.success(`Copied to clipboard!`, {theme: "colored", className:"", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
  }

  
  return (
    
    <div className={`h-auto flex flex-1 overflow-scroll justify-around gap-1 transform duration-150 ease-in-out relative mb-6`}>
         <div id="results_section" className='w-[90%] relative flex gap-4 flex-col'>
        {searchResults.map((result, i) => {
          return (
            <CodeResultCard key={i} result={result}/>
          )

        })}
      </div>
      {/* <div id="results_section" className='w-[30%] px-6 h-full'>
        <button onClick={() => generateCode()} className='text-sm mb-3 font-normal leading-6 border shadow-inner p-4 rounded-xl'>👋🏻 Click here! to <u>Generative AI</u> for the prompt "<b>{searchQuery}</b>"<p className="text-violet-600 font-semibold">from External API.</p> </button>
        {!codeResponseLoading ? (
          codeResponse.length!==0 && <GenerativeTextBox ai_response={codeResponse}/>
        ):(
            <ThreeDots fill="transparent" className='stroke-skin-accent m-auto mt-20 h-20 w-20' />
        )}
      </div> */}
    </div>
  )
}

export default SnippetResultsSection