import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import detector from "i18next-browser-languagedetector";
import { I18nextProvider,initReactI18next } from "react-i18next";
import i18next from "i18next";
import { GoogleOAuthProvider } from '@react-oauth/google';

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";

i18next.use(detector).use(initReactI18next).init({
	interpolation: { escapeValue: false }, // React already does escaping
	fallbackLng: "us", // language to use
	resources: {
		us: {
			common: common_en, // 'common' is our custom namespace
		},
		de: {
			common: common_de,
		},
		fr: {
			common: common_fr,
		}
	},
});
//679457721052-c924v4u661ffubuesv4m948097ujao8a.apps.googleusercontent.com
const root = ReactDOM.createRoot(document.getElementById('root'));
const azure_client_id = "679457721052-7u5fau3o27js7d4qvqoi15t5tb3bvman.apps.googleusercontent.com"
root.render(
//   <GoogleOAuthProvider clientId={dev_client_id}>
  <GoogleOAuthProvider clientId={azure_client_id}>
        <App />
  </GoogleOAuthProvider>

);

