import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { appContext } from "../App";
import { Bounce, toast } from 'react-toastify';
import { postOpenAiToken } from "./Auth";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: "auto",
      width: "auto",
      padding: 0
    },
    overlay: {zIndex: 100, background: "rgba(0,0,0,0.5)"}

  };

function OpenAITokenEditor({modalIsOpen, closeEvent}) {

    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    const appState = useContext(appContext).state;
    const token = sessionStorage.getItem("openAiToken")
    const [isLoading, setIsLoading] = useState(false);

    const [openAiToken, setOpenAiToken] = useState("")
    const [openAiTokenType, setOpenAiTokenType] = useState("openai")
    const [openAiModelName, setOpenAiModelName] = useState("")
    const [openAiModelVersion, setOpenAiModelVersion] = useState("")
    const [openAiResourceEndpoint, setOpenAiResourceEndpoint] = useState("")

    useEffect(() => {
        if (openAiTokenType === "azure") {
            setOpenAiModelName("DeveloperAssistModel")
            setOpenAiModelVersion("2023-03-15-preview")
            setOpenAiResourceEndpoint("https://developerassist.openai.azure.com/")
        } else {
            setOpenAiModelName("")
            setOpenAiModelVersion("")
            setOpenAiResourceEndpoint("")
        }


    }, [openAiTokenType])

    const handleOpenAiTokenUpdate = (e, token) => {
      e.preventDefault()
      setIsLoading(true)
      postOpenAiToken(userDetails.name, userDetails.email, openAiToken, openAiTokenType, openAiModelName, openAiModelVersion, openAiResourceEndpoint).then(status => {
          if (status === 200) {
              sessionStorage.setItem('openAiToken', token.length >0 ? token.slice(0, 3) + " ... " + token.slice(-3) + ` (${openAiTokenType})` : "");
              toast.success(`Token updated succesfully`, {theme: "colored", className:"text-[12px]", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
              setIsLoading(false)
              closeEvent()
          }
          else {
              toast.error(`We are not able to validate the provided key, please ensure the API key provided is correct.`, {theme: "colored", className:"text-[12px] px-2", transition: Bounce, hideProgressBar:true, position: toast.POSITION.TOP_RIGHT})
              setIsLoading(false)
          }
      })
  }
  return (
    <Modal
    isOpen={modalIsOpen}
    style={customStyles}
    contentLabel="Example Modal" 
    ariaHideApp={false}
    // contentClassName = "border-0"
    className="border-0 absolute outline-none"
    >
        <form onSubmit={(e) => handleOpenAiTokenUpdate(e,openAiToken)} className={`p-8 pb-6 ${appState.theme} bg-skin-primary border border-skin-backdrop rounded-md`}>
          <fieldset disabled={isLoading} className="flex gap-4 flex-col">
            <h1 className='text-skin-accent font-medium text-[19px] text-center gap-1 flex'>Please paste your OpenAI API key below<p className='text-skin-accent'></p></h1>
            {/* <h1 className='text-skin-secondary font-medium text-[12px] text-center gap-1 mb-4 justify-center flex'>This will delete this snippet permanently are you sure?</h1> */}
            <div className="flex justify-between pr-4">
              <div className="text-skin-accent">
                <p className="text-[11px]">key type</p>
                <select
                type="dropdown"
                required
                className="focus-within:border-skin-accent border w-full border-skin-secondary rounded-md outline-none px-2 text-[12px] py-1 bg-transparent"
                defaultValue={openAiTokenType}
                onChange={(e) => {setOpenAiTokenType(e.target.value)}}
                >
                  <option disabled value="">select type</option>
                  <option value="azure">azure</option>
                  <option value="openai">openai</option>
                </select>
              </div>
              <div>
                <p className="text-[11px] ml-1 text-skin-secondary">current key</p>
                <p className={`${token.length!==0 ? "border-skin-backdrop text-skin-secondary text-[12px]":"border-red-500 text-red-500 text-[11px]"} rounded-md  px-2 p-1 `}>{token.length!==0 ? token : "no token added"}</p>
              </div>
            </div>
            {openAiTokenType === "azure" &&
              <>
                <div className="flex justify-between gap-5">
                  <div className="mb-2 text-skin-accent flex-1 flex-grow">
                    <p className="text-[11px] ml-1">deployment name</p>
                    <input
                    type="text"
                    required
                    defaultValue={openAiModelName}
                    onChange={(e) => {setOpenAiModelName(e.target.value)}}
                    placeholder="enter deployment name"
                    className="focus-within:border-skin-accent border w-full border-skin-backdrop rounded-md outline-none px-2 text-[12px] py-1 bg-transparent"
                    />
                  </div>
                  <div className="mb-2 text-skin-accent flex-1 flex-grow">
                    <p className="text-[11px] ml-1">version</p>
                    <input
                    type="text"
                    required
                    defaultValue={openAiModelVersion}
                    onChange={(e) => {setOpenAiModelVersion(e.target.value)}}
                    placeholder="enter model version"
                    className="focus-within:border-skin-accent border w-full border-skin-backdrop rounded-md outline-none px-2 text-[12px] py-1 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-2 text-skin-accent">
                  <p className="text-[11px] ml-1">resource endpoint</p>
                  <input
                  type="text"
                  required
                  defaultValue={openAiResourceEndpoint}
                  onChange={(e) => {setOpenAiResourceEndpoint(e.target.value)}}
                  placeholder="enter azure openai resource endpoint"
                  className="focus-within:border-skin-accent border w-full border-skin-backdrop rounded-md outline-none px-2 text-[12px] py-1 bg-transparent"
                  />
                </div>
              </>
            }
            <div className="mb-2 text-sky-500">
              <p className="text-[11px] ml-1">new key</p>
              <input
              type="password"
              onCopy={(e) => {e.preventDefault()}}
              required
              autoFocus
              onCut={(e) => {e.preventDefault()}}
              placeholder="paste your key here"
              className="border w-full border-sky-500 rounded-md outline-none px-2 text-[12px] py-1 bg-transparent"
              defaultValue={openAiToken}
              onChange={(e) => {setOpenAiToken(e.target.value)}}
              />
            </div>
            <div className="flex justify-between items-center">
              <a href={openAiTokenType==="azure"? "https://learn.microsoft.com/en-us/azure/cognitive-services/openai/how-to/create-resource?pivots=web-portal":"https://platform.openai.com/account/api-keys"} target="_blank" rel="noreferrer" className="text-[11px] text-sky-500 ml-2 underline">where can I get it?</a>
              <div className='flex flex-row-reverse justify-start gap-4'>
                  <button
                  type="submit"
                  // onClick={(e) => submitEvent(e,openAiToken)}
                  className={`flex gap-2 items-center ease-in-out disabled:cursor-not-allowed active:scale-95 rounded-md px-3 p-1 text-[14px] text-green-500 hover:bg-green-500 border hover:text-skin-primary font-semibold border-green-500 transition duration-150 cursor-pointer`}
                  >
                    {isLoading && <AiOutlineLoading3Quarters className="animate-spin "/>}
                    Update
                  </button>
                  <button onClick={(e) => {e.preventDefault();closeEvent(false);}}
                  className={`disabled:cursor-not-allowed active:scale-95 rounded-md px-3 opacity-60 p-1 text-[14px] hover:bg-skin-secondary border-skin-secondary text-skin-secondary hover:text-skin-primary transition duration-150 cursor-pointer`}
                  >Cancel</button>
              </div>
            </div>
            </fieldset>
        </form>
    </Modal>
  )
}

export default OpenAITokenEditor