import React, { useContext, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';
import logo from "../components/assets/logo_icon.png"
import { useNavigate } from "react-router-dom";
import { decodeJWT } from "../utilities/utils";
import { appContext } from "../App";
import { getBearerToken, getOpenAiToken } from "../utilities/Auth";


const Login = () => {
  
    const navigate = useNavigate();
    const appState = useContext(appContext).state
    const appDispatcher = useContext(appContext).dispatcher

    const responseMessage = (response) => {
        appDispatcher({type:"setLoading", value:true})
        let {name, email, picture} = decodeJWT(response.credential)
        const userData = {
            name: name,
            email: email,
            picture: picture,
            isActive:1      
          };

        sessionStorage.setItem('userDetails', JSON.stringify(userData));
        getBearerToken(response.credential).then(access_token => {
            sessionStorage.setItem('bearerToken', access_token);
            getOpenAiToken(email, access_token).then(userInfo => {

                sessionStorage.setItem('userDetails', JSON.stringify({...userInfo, ...userData}));
                sessionStorage.setItem('openAiToken', userInfo.access_token);
                appDispatcher({type:"setLoading", value:false})
                    
            })
        })
        navigate("/developer")
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    return (
        <div className="login-img p-20 text-center relative w-screen h-screen">
            <div className="w-2/5 p-14 border rounded-md bg-gray-100 float-right relative loginBody top-24">
                <div className='mb-4 flex items-center'>
                    <img
                        src={logo}
                        alt="logo"
                        className={`duration-600 h-7 mr-2 inline-block`}
                    />
                    <div className={`flex flex-grow text-[12px]`}>
                        <h1 className={`text-skin-accent`}>developer</h1>
                        <h1 className='text-skin-accent font-bold whitespace-nowrap'>Assistant</h1>
                    </div>
                </div>
                <GoogleLogin width='100%'
                    onSuccess={responseMessage} 
                    onError={errorMessage}
                    theme="filled_black"
                    text="signin_with"
                    shape="circle"
                    useOneTap
                    hosted_domain='atos.net'
                />
            </div>
        </div>

    );
}

export default Login;