/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { MdOutlineColorLens } from 'react-icons/md'
import { appContext} from '../App'
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ThemeMenu() {

  const [t] = useTranslation("common");
  const appState = useContext(appContext).state
  const appDispatcher = useContext(appContext).dispatcher

  const menu_items = [
    [{id:"1", title:"Dark", themeClass:"theme-dark-blue", styles:"block px-4 py-1 font-medium text-sm pt-2 hover:text-slate-900 hover:font-bold"},
    {id:"2", title:"Light", themeClass:"theme-light-blue", styles:"block px-4 py-1 font-medium text-sm hover:text-sky-500 hover:font-bold"},
    // {id:"3", title:"Orange", themeClass:"theme-light-orange", styles:"block px-4 py-1 font-medium text-sm hover:text-orange-400"},
    // {id:"4", title:"Cyan", themeClass:"theme-light-cyan", styles:"block px-4 py-1 font-medium text-sm hover:text-cyan-400"},
    // {id:"5", title:"Red", themeClass:"theme-light-red", styles:"block px-4 py-1 font-medium text-sm hover:text-red-400"},
    // {id:"6", title:"Green", themeClass:"theme-light-green", styles:"block px-4 py-1 font-medium text-sm pb-2 hover:text-green-400"}
    ]
    ]
  const onclickEvent = (themeClass) => {
    appDispatcher({type:"setTheme", value:themeClass})
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="justify-center p-1">
          <MdOutlineColorLens className='text-skin-secondary hover:text-skin-accent duration-100 transition cursor-pointer w-6 h-6'/>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-32 overflow-clip rounded-md shadow-lg bg-white divide-y divide-gray-100 outline-none">
          {menu_items.map((val) => (
            <div key={menu_items.indexOf(val)} className="">
              {val.map(({id, title, themeClass, styles}) => (
                <Menu.Item key={id}>
                  <a
                  href="#"
                  className={styles}
                  onClick={() => onclickEvent(themeClass)}
                >
                  {t(`Search.${title}`)}
                </a>
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default ThemeMenu